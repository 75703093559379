export interface Competitor {
    id: number;
    competitor: string;
}

const mapCompetitor = (input: any): Competitor => ({
    ...input,
});

export const mapCompetitors = (input: any): Competitor[] =>
    input.map(mapCompetitor);

export default mapCompetitor;
