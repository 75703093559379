export default class CallGenderizeIoHttpError extends Error {
    constructor(message: string, error: string) {
        super(message + ': ' + error);
        if (Error?.captureStackTrace) {
            Error.captureStackTrace(this, CallGenderizeIoHttpError);
        }
        this.name = 'CallGenderizeIoHttpError';
        Object.setPrototypeOf(this, CallGenderizeIoHttpError.prototype);
    }
}
