import React, { useState } from 'react';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
} from '@material-ui/core';
import FormBuilder from '../../helpers/forms/FormBuilder';
import { Close } from '@material-ui/icons';
import { AutocompleteOption } from '../../../crm';
import EntityAutocomplete from '../../helpers/forms/EntityAutocomplete';
import { OfficeEntity } from '../../../helpers/entities';

interface Props {
    closeDialog: { (): void };
    contactId?: string;
    responseHandler(): void;
}

const OfficeLinkDialog = ({
    closeDialog,
    contactId,
    responseHandler,
}: Props) => {
    const [selectedOfficeUnique, setSelectedOfficeUnique] = useState<
        string | null
    >(null);

    const autocompleteChangeHandler = (newValue: AutocompleteOption | null) => {
        setSelectedOfficeUnique(newValue ? newValue.value : null);
    };

    return (
        <FormBuilder
            action={
                'contacts/' + contactId + '/offices/' + selectedOfficeUnique
            }
            responseHandler={responseHandler}
            initialValues={{ office_unique: '' }}
            errorMessage="Fehler beim Verknüpfen der Niederlassung!"
        >
            <DialogTitle>
                <Typography component="h2">Niederlassung verknüpfen</Typography>
                <IconButton onClick={closeDialog} size="small" edge="end">
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <EntityAutocomplete
                    entity={OfficeEntity}
                    name="office_unique"
                    onChangeHandler={autocompleteChangeHandler}
                    autoFocus
                    preloadAllEntries
                    minInputLength={0}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    type="submit"
                    color="primary"
                    disabled={!selectedOfficeUnique}
                >
                    Niederlassung verknüpfen
                </Button>
            </DialogActions>
        </FormBuilder>
    );
};

export default OfficeLinkDialog;
