import React, { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import generate from '../../../helpers/password-generator';
import { IconButton, InputAdornment } from '@material-ui/core';
import { Replay, Visibility, VisibilityOff } from '@material-ui/icons';
import { FormBuilderFieldProps } from './FormBuilder';

type Props = FormBuilderFieldProps;

const PasswordField = ({ name, label, required = false }: Props) => {
    const { setFieldValue } = useFormikContext();
    const [pwdVisible, setPwdVisible] = useState<boolean>(false);

    const generatePassword = () => {
        setFieldValue(name, generate());
    };

    const togglePwdVisibility = () => {
        setPwdVisible(prevState => !prevState);
    };

    useEffect(generatePassword, []);

    return (
        <Field
            component={TextField}
            type={pwdVisible ? 'text' : 'password'}
            name={name}
            label={label}
            required={required}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={togglePwdVisibility}>
                            {pwdVisible ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                        <IconButton onClick={generatePassword}>
                            <Replay />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default PasswordField;
