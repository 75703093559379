import React, { ReactElement, useEffect, useState } from 'react';
import useEntities from '../../../hooks/useEntities';
import { Competitor } from '../../../mappers/competitorMapper';
import { CompetitorEntity } from '../../../helpers/entities';
import AutocompleteInputMultiple from '../../helpers/forms/AutocompleteInputMultiple';
import { AutocompleteOption } from '../../../crm';
import { useFormikContext } from 'formik';
import { TextField } from '@material-ui/core';

const ReportAddDialogCompetitors = (): ReactElement => {
    const { entities: competitors } = useEntities<Competitor>(CompetitorEntity);
    const { values } = useFormikContext<any>();
    const [selectedOptions, setSelectedOptions] = useState<
        AutocompleteOption[] | null
    >(null);

    const getOptions = async (): Promise<AutocompleteOption[]> =>
        competitors
            ? CompetitorEntity.autocompleteOptionsMapper(competitors)
            : [];

    useEffect(() => {
        if (competitors === null) {
            return;
        }
        setSelectedOptions(
            values.competitors
                ? CompetitorEntity.autocompleteOptionsMapper(
                      competitors.filter(competitor =>
                          values.competitors.includes(competitor.id.toString())
                      )
                  )
                : []
        );
    }, [competitors]);

    if (competitors === null || selectedOptions === null) {
        return <TextField disabled value="Lädt..." />;
    }

    return (
        <AutocompleteInputMultiple
            getOptions={getOptions}
            name={CompetitorEntity.apiBaseUrl}
            label={CompetitorEntity.namePlural}
            selectedOptions={selectedOptions}
            minInputLength={0}
        />
    );
};

export default ReportAddDialogCompetitors;
