import React, { ReactElement } from 'react';
import { Box, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Warning } from '@material-ui/icons';

interface Props {
    mr?: number;
}

const WarningIcon = ({ mr = 0.5 }: Props): ReactElement => {
    const classes = useStyles();

    return (
        <Box clone mr={mr}>
            <Icon
                component={Warning}
                fontSize="inherit"
                color="error"
                className={classes.iconAlignment}
            />
        </Box>
    );
};

export default React.memo(WarningIcon);

const useStyles = makeStyles({
    iconAlignment: {
        verticalAlign: 'middle',
        marginBottom: '2px',
    },
});
