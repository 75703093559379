import React, { ReactElement, useContext } from 'react';
import {
    createStyles,
    IconButton,
    TableCell,
    TableRow,
    Typography,
} from '@material-ui/core';
import { EntityContext } from '../../../helpers/entities';
import { Report, ReportType, reportTypes } from '../../../mappers/reportMapper';
import { makeStyles } from '@material-ui/core/styles';
import { CrmTheme } from '../../../theme';
import { CallMade } from '@material-ui/icons';
import Short from '../ContactPerson/Short';
import WarningIcon from '../../helpers/WarningIcon';
import { getTextExcerptWithSearchText } from '../../../helpers/getTextExcerptWithSearchText';

interface Props {
    openDialog(): void;
    setDialogReportId(reportId: string): void;
    searchText?: string | null;
}

const ReportRows = ({
    openDialog,
    setDialogReportId,
    searchText,
}: Props): ReactElement => {
    const { entities: reports } = useContext(EntityContext);
    const classes = useStyles();

    const handleDialogOpen = (reportId: string) => {
        setDialogReportId(reportId);
        openDialog();
    };

    if (!reports) {
        return <></>;
    }

    return (
        <>
            {reports.map((report: Report) => {
                const textExcerptWithSearchText = searchText
                    ? getTextExcerptWithSearchText(
                          report.report,
                          searchText,
                          170
                      )
                    : null;

                return (
                    <React.Fragment key={report.id}>
                        <TableRow>
                            <TableCell>{report.date_formatted}</TableCell>
                            <TableCell>
                                {report.contact?.name}
                                <Typography
                                    color="textSecondary"
                                    component="div"
                                    variant="body2"
                                >
                                    {report.contact?.address?.city}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                {reportTypes[report.type as ReportType]}
                                {report.contact_persons.length > 0 && (
                                    <>
                                        <Typography
                                            color="textSecondary"
                                            component="span"
                                            variant="body2"
                                        >
                                            {' '}
                                            mit
                                        </Typography>
                                        <br />
                                        {report.contact_persons.map(
                                            contactPerson => (
                                                <Short
                                                    key={contactPerson.id}
                                                    contactPerson={
                                                        contactPerson
                                                    }
                                                />
                                            )
                                        )}
                                    </>
                                )}
                            </TableCell>
                            <TableCell className={classes.reportRow}>
                                {textExcerptWithSearchText ? (
                                    <>
                                        {
                                            textExcerptWithSearchText.firstTextPart
                                        }
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            className={classes.searchText}
                                        >
                                            {
                                                textExcerptWithSearchText.searchText
                                            }
                                        </Typography>
                                        {
                                            textExcerptWithSearchText.secondTextPart
                                        }
                                    </>
                                ) : (
                                    report.report_excerpt
                                )}
                            </TableCell>
                            <TableCell>{report.user?.name}</TableCell>

                            <TableCell>
                                {report.notification_date ? (
                                    <>
                                        {report.overdue && <WarningIcon />}
                                        {report.notification_date_formatted}
                                    </>
                                ) : (
                                    '-'
                                )}
                            </TableCell>

                            <TableCell>
                                <IconButton
                                    edge="end"
                                    color="secondary"
                                    onClick={() => handleDialogOpen(report.id)}
                                >
                                    <CallMade />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    </React.Fragment>
                );
            })}
        </>
    );
};

export default ReportRows;

const useStyles = makeStyles((theme: CrmTheme) =>
    createStyles({
        reportRow: {
            width: '50%',
        },
        searchText: {
            backgroundColor: `${theme.palette.yellow.main}80`,
            color: theme.palette.yellow.contrastText,
            borderRadius: theme.shape.borderRadius,
            padding: '4px 1px',
        },
    })
);
