import { daysUntilDate } from './date';
import dayjs, { Dayjs } from 'dayjs';

export const daysUntilBirthdayText = (birthdayDate: Dayjs): string => {
    const daysUntilBirthday = daysUntilDate(
        birthdayDate.set('year', dayjs().year())
    );
    switch (true) {
        case daysUntilBirthday === 0:
            return ' (Geburtstag Heute 🎉)';
        case daysUntilBirthday === 1:
            return ' (Geburtstag Morgen!)';
        case daysUntilBirthday <= 31:
            return ` (Geburtstag in ${daysUntilBirthday} Tagen)`;
        default:
            return '';
    }
};
