import React, { ReactElement } from 'react';
import FormBuilderCard from '../../helpers/forms/FormBuilderCard';
import { ContactPerson } from '../../../mappers/contactPersonMapper';
import CrmLabel from '../../helpers/CrmLabel';
import Birthday from '../../helpers/formatting/Birthday';
import { ContactPersonEntity } from '../../../helpers/entities';
import BirthdayPickerField from '../../helpers/forms/BirthdayPickerField';

interface Props {
    contactPerson: ContactPerson;
    reloadHandler: { (): void };
}

const ContactPersonBirthday = ({
    contactPerson,
    reloadHandler,
}: Props): ReactElement => {
    return (
        <FormBuilderCard
            title="Termine"
            apiUrl={`${ContactPersonEntity.apiBaseUrl}/${contactPerson.id}`}
            form={<BirthdayPickerField birthday={contactPerson.birthday} />}
            view={
                <>
                    <CrmLabel>Geburtstag</CrmLabel>
                    {contactPerson.birthday ? (
                        <Birthday birthday={contactPerson.birthday} />
                    ) : (
                        '-'
                    )}
                </>
            }
            reloadHandler={reloadHandler}
            initialValues={{
                birthday: contactPerson.birthday ?? '',
            }}
        />
    );
};

export default ContactPersonBirthday;
