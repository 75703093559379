export interface Brand {
    id: number;
    brand: string;
}

const mapBrand = (input: any): Brand => ({
    ...input,
});

export const mapBrands = (input: any): Brand[] => input.map(mapBrand);

export default mapBrand;
