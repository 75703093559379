import { useState } from 'react';

const useEditable = (): {
    editOpen: { (): void };
    editClose: { (): void };
    isEdited: boolean;
} => {
    const [isEdited, setIsEdited] = useState(false);

    const editClose = () => {
        setIsEdited(false);
    };

    const editOpen = () => {
        setIsEdited(true);
    };

    return { editOpen, editClose, isEdited };
};

export default useEditable;
