import mapContact, { Contact, mapContacts } from '../mappers/contactMapper';
import mapUser, { mapUsers, User } from '../mappers/userMapper';
import mapContactPerson, {
    ContactPerson,
    mapContactPersons,
} from '../mappers/contactPersonMapper';
import { AutocompleteOption } from '../crm';
import React from 'react';
import mapReport, { mapReports, Report } from '../mappers/reportMapper';
import mapOffice, {
    mapOffices,
    Office,
} from '../../../../../../resources/assets/react/entities/Office';
import { IEmpfehler } from '../../../../../../resources/assets/react/types';
import mapEmpfehler, { mapEmpfehlers } from '../mappers/empfehlerMapper';
import Ajax from './Ajax';
import mapBrand, { Brand, mapBrands } from '../mappers/brandMapper';
import mapContactType, {
    ContactType,
    mapContactTypes,
} from '../mappers/contactTypeMapper';
import mapWorkshopSystem, {
    mapWorkshopSystems,
    WorkshopSystem,
} from '../mappers/workshopSystemMapper';
import mapAccidentConsultant, {
    AccidentConsultant,
    mapAccidentConsultants,
} from '../mappers/accidentConsultantMapper';
import mapGroup, { Group, mapGroups } from '../mappers/groupMapper';
import mapPosition, { mapPositions, Position } from '../mappers/positionMapper';
import mapCooperation, {
    Cooperation,
    mapCooperations,
} from '../mappers/cooperationMapper';
import mapCompetitor, {
    Competitor,
    mapCompetitors,
} from '../mappers/competitorMapper';
import mapRival, { mapRivals, Rival } from '../mappers/rivalMapper';
import { File, mapFile, mapFiles } from '../mappers/fileMapper';
import mapCampaign, { Campaign, mapCampaigns } from '../mappers/campaignMapper';

export const getEntities = async <T>(
    entity: Entity<T>,
    searchParams?: Obj
): Promise<T[]> => {
    const response = await Ajax.get<Obj[]>(entity.apiBaseUrl, searchParams);

    return entity.arrayMapper(response);
};

export interface Entity<T> {
    mapper(input: any): T;

    arrayMapper(input: any): T[];

    autocompleteOptionsMapper(input: T[]): AutocompleteOption[];

    autocompleteOptionMapper(input: T): AutocompleteOption;

    apiBaseUrl: string;
    name: string;
    namePlural: string;
    columns: string[];
}

export const EntityContext = React.createContext<{
    entities: any[] | null;
    reloadEntities(): void;
}>({ entities: null, reloadEntities: () => void 0 });

export const ContactEntity: Entity<Contact> = {
    name: 'Kontakt',
    namePlural: 'Kontakte',
    apiBaseUrl: 'contacts',
    mapper: mapContact,
    arrayMapper: mapContacts,
    autocompleteOptionMapper: (entity): AutocompleteOption => ({
        label: entity.name,
        value: entity.id,
        additionalInformation: entity.address?.city,
    }),
    autocompleteOptionsMapper: (entities): AutocompleteOption[] =>
        entities.map(ContactEntity.autocompleteOptionMapper),
    columns: ['Kontakt', 'Gruppe', 'Adresse', 'PLZ', 'Stadt'],
};

export const FileEntity: Entity<File> = {
    name: 'Datei',
    namePlural: 'Dateien',
    apiBaseUrl: 'files',
    mapper: mapFile,
    arrayMapper: mapFiles,
    autocompleteOptionMapper: (entity): AutocompleteOption => ({
        label: entity.label,
        value: entity.id,
    }),
    autocompleteOptionsMapper: (entities): AutocompleteOption[] =>
        entities.map(FileEntity.autocompleteOptionMapper),
    columns: ['File'],
};

export const RivalEntity: Entity<Rival> = {
    name: 'Wettbewerber',
    namePlural: 'Wettbewerber',
    apiBaseUrl: 'rivals',
    mapper: mapRival,
    arrayMapper: mapRivals,
    autocompleteOptionMapper: (entity): AutocompleteOption => ({
        label: entity.name,
        value: entity.id,
    }),
    autocompleteOptionsMapper: (entities): AutocompleteOption[] =>
        entities.map(RivalEntity.autocompleteOptionMapper),
    columns: ['Wettbewerber', 'Adresse', 'PLZ', 'Stadt'],
};

export const ReportEntity: Entity<Report> = {
    name: 'Bericht',
    namePlural: 'Berichte',
    apiBaseUrl: 'reports',
    mapper: mapReport,
    arrayMapper: mapReports,
    autocompleteOptionMapper: (entity): AutocompleteOption => ({
        label: entity.subject, // change this to a more senseful thing if needed
        value: entity.id,
    }),
    autocompleteOptionsMapper: (entities): AutocompleteOption[] =>
        entities.map(ReportEntity.autocompleteOptionMapper),
    columns: [
        'Datum',
        'Kontakt',
        'Typ',
        'Bericht',
        'Verfasser',
        'Wiedervorlage',
        'Aktionen',
    ],
};

export const UserEntity: Entity<User> = {
    name: 'Benutzer',
    namePlural: 'Benutzer',
    apiBaseUrl: 'users',
    mapper: mapUser,
    arrayMapper: mapUsers,
    autocompleteOptionMapper: (entity): AutocompleteOption => ({
        label: entity.nameSortable,
        value: entity.id,
    }),
    autocompleteOptionsMapper: (entities): AutocompleteOption[] =>
        entities.map(UserEntity.autocompleteOptionMapper),
    columns: ['Nachname', 'Vorname', 'E-Mail', 'Rolle'],
};

export const ContactPersonEntity: Entity<ContactPerson> = {
    name: 'Ansprechpartner',
    namePlural: 'Ansprechpartner',
    apiBaseUrl: 'contact_persons',
    mapper: mapContactPerson,
    arrayMapper: mapContactPersons,
    autocompleteOptionMapper: (entity): AutocompleteOption => ({
        label: entity.nameSortable,
        value: entity.id,
    }),
    autocompleteOptionsMapper: (entities): AutocompleteOption[] =>
        entities.map(ContactPersonEntity.autocompleteOptionMapper),
    columns: ['Name', 'Kontakte'],
};

export const OfficeEntity: Entity<Office> = {
    name: 'Niederlassung',
    namePlural: 'Niederlassungen',
    apiBaseUrl: 'offices',
    mapper: mapOffice,
    arrayMapper: mapOffices,
    autocompleteOptionMapper: (entity): AutocompleteOption => ({
        label: entity.name,
        value: entity.unique,
    }),
    autocompleteOptionsMapper: (entities): AutocompleteOption[] =>
        entities.map(OfficeEntity.autocompleteOptionMapper),
    columns: [
        'Niederlassung',
        'Zugriffsbeschränkt',
        'Zugewiesene Benutzer',
        'Vertriebsmitarbeiter',
        'Aktion',
    ],
};

export const BrandEntity: Entity<Brand> = {
    name: 'Herstellermarke',
    namePlural: 'Herstellermarken',
    apiBaseUrl: 'brands',
    mapper: mapBrand,
    arrayMapper: mapBrands,
    autocompleteOptionMapper: (entity): AutocompleteOption => ({
        label: entity.brand,
        value: entity.id.toString(),
    }),
    autocompleteOptionsMapper: (entities): AutocompleteOption[] =>
        entities.map(BrandEntity.autocompleteOptionMapper),
    columns: ['Marke'],
};

export const CompetitorEntity: Entity<Competitor> = {
    name: 'Wettbewerber',
    namePlural: 'Wettbewerber',
    apiBaseUrl: 'competitors',
    mapper: mapCompetitor,
    arrayMapper: mapCompetitors,
    autocompleteOptionMapper: (entity): AutocompleteOption => ({
        label: entity.competitor,
        value: entity.id.toString(),
    }),
    autocompleteOptionsMapper: (entities): AutocompleteOption[] =>
        entities.map(CompetitorEntity.autocompleteOptionMapper),
    columns: ['Wettbewerber'],
};

export const CooperationEntity: Entity<Cooperation> = {
    name: 'Kooperation',
    namePlural: 'Kooperationen',
    apiBaseUrl: 'cooperations',
    mapper: mapCooperation,
    arrayMapper: mapCooperations,
    autocompleteOptionMapper: (entity): AutocompleteOption => ({
        label: entity.cooperation,
        value: entity.id.toString(),
    }),
    autocompleteOptionsMapper: (entities): AutocompleteOption[] =>
        entities.map(CooperationEntity.autocompleteOptionMapper),
    columns: ['Kooperation'],
};

export const GroupEntity: Entity<Group> = {
    name: 'Gruppe',
    namePlural: 'Gruppen',
    apiBaseUrl: 'groups',
    mapper: mapGroup,
    arrayMapper: mapGroups,
    autocompleteOptionMapper: (entity): AutocompleteOption => ({
        label: entity.group,
        value: entity.id.toString(),
    }),
    autocompleteOptionsMapper: (entities): AutocompleteOption[] =>
        entities.map(GroupEntity.autocompleteOptionMapper),
    columns: ['Gruppe'],
};

export const WorkshopSystemEntity: Entity<WorkshopSystem> = {
    name: 'Werkstattsystem',
    namePlural: 'Werkstattsysteme',
    apiBaseUrl: 'workshop_systems',
    mapper: mapWorkshopSystem,
    arrayMapper: mapWorkshopSystems,
    autocompleteOptionMapper: (entity): AutocompleteOption => ({
        label: entity.workshop_system,
        value: entity.id.toString(),
    }),
    autocompleteOptionsMapper: (entities): AutocompleteOption[] =>
        entities.map(WorkshopSystemEntity.autocompleteOptionMapper),
    columns: ['Werkstattsystem'],
};

export const AccidentConsultantEntity: Entity<AccidentConsultant> = {
    name: 'Gutachter',
    namePlural: 'Gutachter',
    apiBaseUrl: 'accident_consultants',
    mapper: mapAccidentConsultant,
    arrayMapper: mapAccidentConsultants,
    autocompleteOptionMapper: (entity): AutocompleteOption => ({
        label: entity.accident_consultant,
        value: entity.id.toString(),
    }),
    autocompleteOptionsMapper: (entities): AutocompleteOption[] =>
        entities.map(AccidentConsultantEntity.autocompleteOptionMapper),
    columns: ['Gutachter'],
};

export const PositionEntity: Entity<Position> = {
    name: 'Position',
    namePlural: 'Positionen',
    apiBaseUrl: 'positions',
    mapper: mapPosition,
    arrayMapper: mapPositions,
    autocompleteOptionMapper: (entity): AutocompleteOption => ({
        label: entity.position_m,
        value: entity.id.toString(),
    }),
    autocompleteOptionsMapper: (entities): AutocompleteOption[] =>
        entities.map(PositionEntity.autocompleteOptionMapper),
    columns: ['Position'],
};

export const ContactTypeEntity: Entity<ContactType> = {
    name: 'Kontakttyp',
    namePlural: 'Kontakttypen',
    apiBaseUrl: 'contact_types',
    mapper: mapContactType,
    arrayMapper: mapContactTypes,
    autocompleteOptionMapper: (entity): AutocompleteOption => ({
        label: entity.contact_type,
        value: entity.id.toString(),
    }),
    autocompleteOptionsMapper: (entities): AutocompleteOption[] =>
        entities.map(ContactTypeEntity.autocompleteOptionMapper),
    columns: ['Kundentyp'],
};

export const EmpfehlerEntity: Entity<IEmpfehler> = {
    name: 'Empfehler',
    namePlural: 'Empfehler',
    apiBaseUrl: 'empfehlers',
    mapper: mapEmpfehler,
    arrayMapper: mapEmpfehlers,
    autocompleteOptionMapper: (entity): AutocompleteOption => ({
        label: entity.name,
        value: entity.unique,
    }),
    autocompleteOptionsMapper: (entities): AutocompleteOption[] =>
        entities.map(EmpfehlerEntity.autocompleteOptionMapper),
    columns: ['Empfehler'],
};

export const CampaignEntity: Entity<Campaign> = {
    name: 'Kampagne',
    namePlural: 'Kampagnen',
    apiBaseUrl: 'campaigns',
    mapper: mapCampaign,
    arrayMapper: mapCampaigns,
    autocompleteOptionMapper: (entity): AutocompleteOption => ({
        label: entity.campaign,
        value: entity.id.toString(),
    }),
    autocompleteOptionsMapper: (entities): AutocompleteOption[] =>
        entities.map(CampaignEntity.autocompleteOptionMapper),
    columns: ['Kampagne'],
};
