import React from 'react';
import { ContactPerson } from '../../../mappers/contactPersonMapper';
import CrmCard from '../../helpers/CrmCard';
import {
    createStyles,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { Close, Edit, Link as LinkIcon, LinkOff } from '@material-ui/icons';
import useEditable from '../../../hooks/useEditable';
import useDialog from '../../../hooks/useDialog';
import Ajax from '../../../helpers/Ajax';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { CrmTheme } from '../../../theme';
import CrmDialog from '../../helpers/CrmDialog';
import ContactLinkDialog from './ContactLinkDialog';
import logError from '../../../errors/logError';
import { ContactEntity } from '../../../helpers/entities';

interface Props {
    contactPerson: ContactPerson;
    reloadContactPerson(): void;
}

const Contacts = ({ contactPerson, reloadContactPerson }: Props) => {
    const { isEdited, editOpen, editClose } = useEditable();
    const {
        openDialog: openContactLinkDialog,
        closeDialog: closeContactLinkDialog,
        dialogOpen: contactLinkDialogOpen,
    } = useDialog();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const contactLinkResponseHandler = () => {
        reloadContactPerson();
        closeContactLinkDialog();
    };

    const handleContactUnlink = async (contactId: string) => {
        try {
            await Ajax.delete<any>(
                ContactEntity.apiBaseUrl +
                    '/' +
                    contactId +
                    '/contact_persons/' +
                    contactPerson.id
            );

            reloadContactPerson();
        } catch (e) {
            enqueueSnackbar('Fehler beim Lösen des Kontaktes!', {
                variant: 'error',
            });
            logError(e);
        }
    };

    return (
        <CrmCard
            title="Kontakte"
            titleAction={
                isEdited ? (
                    <IconButton size="small" onClick={editClose}>
                        <Close />
                    </IconButton>
                ) : (
                    <>
                        <IconButton
                            size="small"
                            onClick={openContactLinkDialog}
                        >
                            <LinkIcon />
                        </IconButton>
                        <IconButton size="small" onClick={editOpen}>
                            <Edit />
                        </IconButton>
                    </>
                )
            }
        >
            {contactPerson.contacts?.length ? (
                <List dense disablePadding className={classes.list}>
                    {contactPerson.contacts.map(contact => (
                        <ListItem disableGutters key={contact.id}>
                            <ListItemText
                                primary={
                                    <Link to={'/contact/' + contact.id}>
                                        {contact.name}
                                    </Link>
                                }
                            />
                            {isEdited && (
                                <ListItemSecondaryAction>
                                    <Tooltip
                                        title={
                                            (contactPerson.contacts?.length ??
                                                1) === 1
                                                ? 'Muss mindestens einem Kontakt zugeordnet sein.'
                                                : ''
                                        }
                                        placement="top"
                                        arrow
                                    >
                                        <span>
                                            <IconButton
                                                edge="end"
                                                disabled={
                                                    (contactPerson?.contacts
                                                        ?.length ?? 1) === 1
                                                }
                                            >
                                                <LinkOff
                                                    fontSize="small"
                                                    onClick={() =>
                                                        handleContactUnlink(
                                                            contact.id
                                                        )
                                                    }
                                                />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </ListItemSecondaryAction>
                            )}
                        </ListItem>
                    ))}
                </List>
            ) : (
                <Typography variant="body2">
                    Bisher wurden keine Kontakte zugewiesen.
                </Typography>
            )}
            <CrmDialog
                dialogOpen={contactLinkDialogOpen}
                closeDialog={closeContactLinkDialog}
                maxWidth="sm"
            >
                <ContactLinkDialog
                    closeDialog={closeContactLinkDialog}
                    responseHandler={contactLinkResponseHandler}
                    contactPersonId={contactPerson.id}
                />
            </CrmDialog>
        </CrmCard>
    );
};

export default Contacts;

const useStyles = makeStyles((theme: CrmTheme) =>
    createStyles({
        list: {
            marginTop: '-10px',
        },
    })
);
