import React, { ReactElement } from 'react';
import { Print } from '@material-ui/icons';
import FormattingBase from './FormattingBase';

interface Props {
    fax: string;
}

const Fax = ({ fax }: Props): ReactElement => {
    return <FormattingBase label={fax} icon={Print} />;
};

export default Fax;
