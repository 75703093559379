import { AutocompleteOption } from '../crm';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Entity, getEntities } from '../helpers/entities';
import logError from '../errors/logError';

const useEntitiesAutocomplete = <T>(
    entity: Entity<T>
): {
    getAutocompleteEntities(
        search?: string,
        additionalSearchParams?: Obj
    ): Promise<AutocompleteOption[]>;
} => {
    const [cachedEntities, setCachedEntities] = useState<{
        [key: string]: AutocompleteOption[];
    }>({});
    const { enqueueSnackbar } = useSnackbar();

    const getAutocompleteEntities = async (
        search = '',
        additionalSearchParams: Obj
    ): Promise<AutocompleteOption[]> => {
        try {
            if (cachedEntities[search] !== undefined) {
                return [...cachedEntities[search]]; // if we just return the entities, no Promise is returned
            }

            const searchParams = search ? { search } : undefined;
            const response = await getEntities(entity, {
                ...searchParams,
                ...additionalSearchParams,
            });
            const entityList = entity.autocompleteOptionsMapper(response);

            setCachedEntities(prevState => {
                prevState[search] = entityList;

                return prevState;
            });

            return entityList;
        } catch (e) {
            enqueueSnackbar(`Fehler beim Laden der ${entity.namePlural}!`, {
                variant: 'error',
            });
            logError(e);
            return [];
        }
    };

    return {
        getAutocompleteEntities,
    };
};

export default useEntitiesAutocomplete;
