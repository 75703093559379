export type Gender = 'm' | 'f' | 'd';

export const getGenderChar = (gender: Gender): string => {
    const genderChar = {
        m: 9794,
        f: 9792,
        d: 9893,
    };

    return String.fromCharCode(genderChar[gender]);
};
