import Webcam from 'react-webcam';
import { Crop } from 'react-image-crop';

export const cropImage = (
    webcamRef: Webcam | null,
    videoConstraints: MediaTrackConstraints,
    crop: Partial<Crop>
): HTMLCanvasElement | null => {
    if (typeof webcamRef?.getCanvas === 'undefined') {
        return null;
    }

    const videoWidth = webcamRef.video?.videoWidth as number;
    const videoHeight = webcamRef.video?.videoHeight as number;
    const videoConstraintsWidth = videoConstraints.width as number;
    const videoConstraintsAspectRatio = videoConstraints.aspectRatio as number;
    const percentToNumber = 0.01;

    if (
        crop.x === undefined ||
        crop.y === undefined ||
        crop.width === undefined ||
        crop.height === undefined
    ) {
        return null;
    }

    // if videoWidth < videoConstraintsWidth
    const upScale = videoConstraintsWidth / videoWidth;
    // to get cropped image with 960px width
    const downScale =
        960 / (crop.width * percentToNumber * videoConstraintsWidth);

    const canvas = webcamRef.getCanvas({
        width: videoWidth * upScale * downScale,
        height: videoHeight * upScale * downScale,
    });

    if (canvas === null) {
        return null;
    }

    const ctx = canvas.getContext('2d');

    if (ctx === null) {
        return null;
    }

    ctx.imageSmoothingQuality = 'high';

    const cropX = crop.x * percentToNumber * canvas.width;
    let cropY = crop.y * percentToNumber * canvas.height;
    const cropWidth = crop.width * percentToNumber * canvas.width;
    let cropHeight = crop.height * percentToNumber * canvas.height;

    if (videoWidth / videoHeight !== videoConstraintsAspectRatio) {
        const calculatedHeight = canvas.width / videoConstraintsAspectRatio;

        cropHeight = crop.height * percentToNumber * calculatedHeight;
        cropY =
            crop.y * percentToNumber * calculatedHeight +
            (canvas.height - calculatedHeight) / 2;
    }

    const croppedImage = ctx.getImageData(cropX, cropY, cropWidth, cropHeight);

    canvas.width = croppedImage.width;
    canvas.height = croppedImage.height;
    ctx.putImageData(croppedImage, 0, 0);

    return canvas;
};
