import React, { ReactElement } from 'react';
import FormattingBase from './FormattingBase';
import { Mail } from '@material-ui/icons';

interface Props {
    email: string;
}

const Email = ({ email }: Props): ReactElement => {
    return (
        <FormattingBase
            label={email}
            href={`mailto:${email}`}
            title={`E-Mail schreiben an ${email}`}
            icon={Mail}
        />
    );
};

export default Email;
