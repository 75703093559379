import React, { ReactElement, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import { FormBuilderFieldProps } from '../../helpers/forms/FormBuilder';
import { Box, Typography } from '@material-ui/core';
import { Contact, mapContacts } from '../../../mappers/contactMapper';
import logError from '../../../errors/logError';
import Ajax from '../../../helpers/Ajax';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { useDebounce } from 'react-use';

interface Props extends FormBuilderFieldProps {
    autoFocus?: boolean;
}

const NameField = ({
    name,
    label,
    required = false,
    autoFocus = false,
}: Props): ReactElement => {
    const [similarContacts, setSimilarContacts] = useState<Contact[]>([]);
    const { values } = useFormikContext<Obj>();
    const { enqueueSnackbar } = useSnackbar();

    const fetchSimilarContacts = async (name: string) => {
        if (name?.trim().length < 3) {
            setSimilarContacts([]);
            return;
        }

        try {
            const response = await Ajax.get('contacts-similar', { name });
            setSimilarContacts(mapContacts(response));
        } catch (e) {
            enqueueSnackbar('Fehler bei der Abfrage ähnlicher Kontakte', {
                variant: 'error',
            });
            logError(e);
        }
    };

    useDebounce(
        () => {
            fetchSimilarContacts(values[name]);
        },
        500,
        [values[name]]
    );

    return (
        <div>
            <Field
                component={TextField}
                name={name}
                label={label}
                required={required}
                autoFocus={autoFocus}
                autoComplete="off"
            />
            {similarContacts.length > 0 && (
                <Box mt={1} maxHeight={6 * 20 + 15} overflow="scroll">
                    <Typography gutterBottom variant="body2">
                        Ähnliche Kontakte gefunden:
                    </Typography>
                    {similarContacts.map(similarContact => (
                        <Box key={similarContact.id}>
                            <Link to={`/contact/${similarContact.id}`}>
                                {similarContact.name}
                            </Link>
                        </Box>
                    ))}
                </Box>
            )}
        </div>
    );
};

export default NameField;
