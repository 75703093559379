import React, { ReactElement, useContext } from 'react';
import { EntityContext } from '../../../helpers/entities';
import { User } from '../../../mappers/userMapper';
import UserCells from './UserCells';

const UserRows = (): ReactElement => {
    const { entities: users } = useContext(EntityContext);

    return (
        <>
            {users &&
                users.map((user: User) => (
                    <UserCells user={user} key={user.id} />
                ))}
        </>
    );
};

export default UserRows;
