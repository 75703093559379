import mapContact, { Contact } from './contactMapper';
import { mapRentalCarReports, RentalCarReport } from './rentalCarReportMapper';

export interface AboptimalUser {
    id: string;
    name: string | null;
    email: string | null;
    postcode: string | null;
    city: string | null;
    address: string | null;
    hidden: boolean;
    contact?: Contact | null;
    rental_car_reports?: RentalCarReport[];
}

const mapAboptimalUser = (input: any): AboptimalUser => ({
    id: input.id,
    name: input.name,
    email: input.email,
    postcode: input.postcode,
    city: input.city,
    address: input.address,
    hidden: !!input.hidden,
    ...(input.contact && { contact: mapContact(input.contact) }),
    ...(input.rental_car_reports && {
        rental_car_reports: mapRentalCarReports(input.rental_car_reports),
    }),
});

export const mapAboptimalUsers = (inputs: any[]): AboptimalUser[] =>
    inputs.map(mapAboptimalUser);

export default mapAboptimalUser;
