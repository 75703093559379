import CrmDialog from './CrmDialog';
import {
    Box,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
} from '@material-ui/core';
import EditCloseAction from './actions/EditCloseAction';
import React, { ReactElement, ReactNode } from 'react';
import { Help } from '@material-ui/icons';
import useDialog from '../../hooks/useDialog';

interface Props {
    helpers: { heading: string; text: string | ReactNode }[];
}

const HelpDialog = ({ helpers }: Props): ReactElement => {
    const {
        dialogOpen: helpDialogOpen,
        closeDialog: closeHelpDialog,
        openDialog: openHelpDialog,
    } = useDialog();

    return (
        <>
            <IconButton onClick={openHelpDialog}>
                <Help />
            </IconButton>
            <CrmDialog
                dialogOpen={helpDialogOpen}
                closeDialog={closeHelpDialog}
                maxWidth="md"
                confirmClose={false}
            >
                <DialogTitle>
                    <Typography variant="h1" gutterBottom color="textSecondary">
                        Hilfe
                    </Typography>
                    <EditCloseAction
                        editClose={closeHelpDialog}
                        edge="end"
                        noCloseConfirmation
                    />
                </DialogTitle>
                <DialogContent>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Box mb={2} clone>
                            <div>
                                {helpers.map(helper => (
                                    <>
                                        <Typography
                                            variant="h3"
                                            component="h2"
                                            gutterBottom
                                            color="textSecondary"
                                        >
                                            <b>{helper.heading}</b>
                                        </Typography>
                                        <Typography
                                            variant="subtitle1"
                                            component="div"
                                            gutterBottom
                                            color="textSecondary"
                                        >
                                            {helper.text}
                                        </Typography>
                                        <br />
                                    </>
                                ))}
                            </div>
                        </Box>
                    </Box>
                </DialogContent>
            </CrmDialog>
        </>
    );
};

export default HelpDialog;
