import { ContactPerson, mapContactPersons } from './contactPersonMapper';
import {
    ContactDetailsHierarchical,
    mapContactDetailsHierarchical,
} from './contactDetailMapper';
import mapAddress, { Address } from './addressMapper';
import { mapReports, Report } from './reportMapper';
import { Brand } from './brandMapper';
import mapOffice, {
    mapOffices,
    Office,
} from '../../../../../../resources/assets/react/entities/Office';
import { IEmpfehler } from '../../../../../../resources/assets/react/types';
import mapEmpfehler from './empfehlerMapper';
import { ContactType, mapContactTypes } from './contactTypeMapper';
import { mapWorkshopSystems, WorkshopSystem } from './workshopSystemMapper';
import {
    AccidentConsultant,
    mapAccidentConsultants,
} from './accidentConsultantMapper';
import { Contract, mapContracts } from './contractMapper';
import mapGroup, { Group } from './groupMapper';
import { Cooperation } from './cooperationMapper';
import { Competitor, mapCompetitors } from './competitorMapper';
import { File, mapFiles } from './fileMapper';
import { mapRentalCarReports, RentalCarReport } from './rentalCarReportMapper';
import mapAboptimalUser, { AboptimalUser } from './aboptimalUserMapper';
import mapCampaign, { Campaign } from './campaignMapper';

export interface Contact {
    id: string;
    contact_id: string | null;
    empfehler?: IEmpfehler | null;
    offices?: Office[];
    main_office?: Office;
    contact_persons?: ContactPerson[];
    contact_details?: ContactDetailsHierarchical | null;
    reports?: Report[];
    name: string;
    address?: Address;
    segmentation: string;
    conditions: string;
    type: string;
    brands?: Brand[];
    cooperations?: Cooperation[];
    group?: Group;
    group_id: number | null;
    competitors?: Competitor[];
    contact_types?: ContactType[];
    workshop_systems?: WorkshopSystem[];
    accident_consultants?: AccidentConsultant[];
    contracts?: Contract[];
    files?: File[];
    contacting_forbidden: boolean;
    note: string | null;
    rental_car_reports?: RentalCarReport[];
    aboptimal_user_id?: string;
    aboptimal_user?: AboptimalUser | null;
    campaign?: Campaign;
    campaign_id: number | null;
}

const mapContact = (input: any): Contact => ({
    ...input,
    ...(input.contact_details && {
        contact_details: mapContactDetailsHierarchical(input.contact_details),
    }),
    ...(input.reports && {
        reports: mapReports(input.reports),
    }),
    ...(input.empfehler && {
        empfehler: mapEmpfehler(input.empfehler),
    }),
    ...(input.offices && {
        offices: mapOffices(input.offices),
    }),
    ...(input.group && {
        group: mapGroup(input.group),
    }),

    ...(input.mainOffice && {
        main_office: mapOffice(input.mainOffice),
    }),
    ...(input.contact_types && {
        contact_types: mapContactTypes(input.contact_types),
    }),
    ...(input.workshop_systems && {
        workshop_systems: mapWorkshopSystems(input.workshop_systems),
    }),
    ...(input.competitors && {
        competitors: mapCompetitors(input.competitors),
    }),
    ...(input.accident_consultants && {
        accident_consultants: mapAccidentConsultants(
            input.accident_consultants
        ),
    }),
    ...(input.contact_persons && {
        contact_persons: mapContactPersons(input.contact_persons),
    }),
    ...(input.address && {
        address: mapAddress(input.address),
    }),
    ...(input.contracts && {
        contracts: mapContracts(input.contracts),
    }),
    ...(input.files && {
        files: mapFiles(input.files),
    }),
    ...(input.rental_car_reports && {
        rental_car_reports: mapRentalCarReports(input.rental_car_reports),
    }),
    ...(input.aboptimal_user && {
        aboptimal_user: mapAboptimalUser(input.aboptimal_user),
    }),
    ...(input.campaign && {
        campaign: mapCampaign(input.campaign),
    }),

    contacting_forbidden: !!input.contacting_forbidden,
});

export const mapContacts = (input: any): Contact[] => input.map(mapContact);

export default mapContact;
