import React, { ChangeEvent, ReactElement, useState } from 'react';
import PaginationPage from './helpers/PaginationPage';
import ContactPersonRows from './modules/ContactPersons/ContactPersonRows';
import { ContactPersonEntity, OfficeEntity } from '../helpers/entities';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
} from '@material-ui/core';
import EntityAutocompleteMultiple from './helpers/forms/EntityAutocompleteMultiple';
import { Check } from '@material-ui/icons';

const ContactPersons = (): ReactElement => {
    const [mergeContactsChecked, setMergeContactsCheck] =
        useState<boolean>(false);
    const [
        mergeContactsWithContactingForbiddenChecked,
        setMergeContactsWithContactingForbiddenCheck,
    ] = useState<boolean>(false);
    const [withContactingForbiddenChecked, setWithContactingForbiddenCheck] =
        useState<boolean>(false);
    const [downloadOptions, setDownloadOptions] = useState<Obj>({});

    const mergeContactsChange = (
        event: ChangeEvent<HTMLInputElement>,
        value: boolean
    ) => {
        setMergeContactsCheck(value);
        if (mergeContactsChecked) {
            setMergeContactsWithContactingForbiddenCheck(false);
        }
        setDownloadOptions(prevState => {
            prevState.merge_contacts = value ? '1' : '0';
            prevState.merge_contacts_with_contacting_forbidden =
                prevState.merge_contacts
                    ? '0'
                    : prevState.merge_contacts_with_contacting_forbidden;
            return prevState;
        });
    };

    const mergeContactsWithContactingForbiddenChange = (
        event: ChangeEvent<HTMLInputElement>,
        value: boolean
    ) => {
        setMergeContactsWithContactingForbiddenCheck(value);
        if (!mergeContactsChecked) setMergeContactsCheck(true);

        setDownloadOptions(prevState => {
            prevState.merge_contacts_with_contacting_forbidden = value
                ? '1'
                : '0';
            prevState.merge_contacts = !mergeContactsChecked
                ? prevState.merge_contacts_with_contacting_forbidden
                : '1';
            return prevState;
        });
    };

    const withContactingForbiddenChange = (
        event: ChangeEvent<HTMLInputElement>,
        value: boolean
    ) => {
        setWithContactingForbiddenCheck(value);
        setDownloadOptions(prevState => {
            prevState.with_contacting_forbidden = value ? '1' : '0';
            return prevState;
        });
    };

    const filters = (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={3}>
                <EntityAutocompleteMultiple
                    entity={OfficeEntity}
                    name="filter_offices"
                    minInputLength={0}
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <IconButton type="submit" color="secondary">
                    <Check />
                </IconButton>
            </Grid>
        </Grid>
    );

    return (
        <PaginationPage
            entity={ContactPersonEntity}
            withRelations={['address']}
            filters={filters}
            downloadable
            downloadOptions={downloadOptions}
            downloadDialogContent={
                <FormControl component="fieldset">
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={withContactingForbiddenChecked}
                                    onChange={withContactingForbiddenChange}
                                    name="with_contacting_forbidden"
                                />
                            }
                            label="Auch Ansprechpartner exportieren, die keinen Kontakt wünschen"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={mergeContactsChecked}
                                    onChange={mergeContactsChange}
                                    name="merge_contacts"
                                />
                            }
                            label="Kontakte anhängen"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={
                                        mergeContactsWithContactingForbiddenChecked
                                    }
                                    onChange={
                                        mergeContactsWithContactingForbiddenChange
                                    }
                                    name="merge_contacts_with_contacting_forbidden"
                                />
                            }
                            label="Auch Kontakte anhängen, die keinen Kontakt wünschen"
                        />
                    </FormGroup>
                </FormControl>
            }
        >
            <ContactPersonRows />
        </PaginationPage>
    );
};

export default ContactPersons;
