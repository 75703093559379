import HttpBaseError from './HttpBaseError';

export default class HttpAuthorizationError extends HttpBaseError {
    constructor(traceId: string) {
        super(traceId, 'Forbidden');
        if (Error?.captureStackTrace) {
            Error.captureStackTrace(this, HttpAuthorizationError);
        }
        this.name = 'HttpAuthorizationError';
        Object.setPrototypeOf(this, HttpAuthorizationError.prototype);
    }
}
