interface TextParts {
    firstTextPart: string;
    searchText: string;
    secondTextPart: string;
}

export const getTextExcerptWithSearchText = (
    text: string,
    searchText: string,
    excerptLength: number
): TextParts | null => {
    const searchIndex = text.toLowerCase().indexOf(searchText.toLowerCase());

    // search text not found
    if (searchIndex === -1) {
        return null;
    }

    // bad excerpt length
    if (excerptLength <= 0) {
        return {
            firstTextPart: '',
            searchText: '',
            secondTextPart: '',
        };
    }

    if (excerptLength <= searchText.length) {
        return {
            firstTextPart: '',
            searchText: text.substring(
                searchIndex,
                searchIndex + excerptLength // cut search text if necessary
            ),
            secondTextPart: '',
        };
    }

    const partLength = Math.floor((excerptLength - searchText.length) / 2);

    // make sure that length of (first part + search text + second part) = excerpt length
    // first case: begin of text = begin of first part (or search text)
    if (searchIndex - partLength <= 0) {
        return {
            firstTextPart: text.substring(0, searchIndex),
            searchText: text.substring(
                searchIndex,
                searchIndex + searchText.length
            ),
            secondTextPart: text.substring(
                searchIndex + searchText.length,
                excerptLength
            ),
        };
    }

    // second case: end of text = end of second part (or search text)
    if (searchIndex + searchText.length + partLength >= text.length) {
        return {
            firstTextPart: text.substring(
                text.length - excerptLength,
                searchIndex
            ),
            searchText: text.substring(
                searchIndex,
                searchIndex + searchText.length
            ),
            secondTextPart: text.substring(searchIndex + searchText.length),
        };
    }

    // if excerpt length - search text length = odd => second part 1 char longer
    return {
        firstTextPart: text.substring(searchIndex - partLength, searchIndex),
        searchText: text.substring(
            searchIndex,
            searchIndex + searchText.length
        ),
        secondTextPart: text.substring(
            searchIndex + searchText.length,
            searchIndex - partLength + excerptLength
        ),
    };
};
