import HttpError from '../errors/HttpError';
import Cookies from 'js-cookie';
import { AUTH_COOKIE_NAME } from '../stores/AuthStore';
import HttpValidationError from '../errors/HttpValidationError';
import HttpAuthorizationError from '../errors/HttpAuthorizationError';
import HttpNotFoundError from '../errors/HttpNotFoundError';
import { v4 as uuidv4 } from 'uuid';
import HttpAuthenticationError from '../errors/HttpAuthenticationError';
import HttpBadRequestError from '../errors/HttpBadRequestError';
import HttpAuthenticationTimeoutError from '../errors/HttpAuthenticationTimeoutError';

enum AjaxMethods {
    GET = 'GET',
    POST = 'POST',
    DELETE = 'DELETE',
}

export type ContentType = 'json' | 'formData';

interface CallParams {
    url: string;
    urlSearchParams?: Obj;
    method: AjaxMethods;
    data?: Obj;
    contentType?: ContentType;
    shouldDownload?: boolean;
}

export default class Ajax {
    public static async post<T>(
        url: string,
        data: Obj,
        contentType?: ContentType
    ): Promise<T> {
        return this.call({
            url,
            data,
            method: AjaxMethods.POST,
            contentType,
        });
    }

    public static async get<T>(url: string, urlSearchParams?: Obj): Promise<T> {
        return this.call({
            url: url,
            urlSearchParams: urlSearchParams,
            method: AjaxMethods.GET,
        });
    }

    public static async delete<T>(url: string): Promise<T> {
        return this.call({
            url: url,
            method: AjaxMethods.DELETE,
        });
    }

    public static async download(
        url: string,
        urlSearchParams?: Obj
    ): Promise<void> {
        return this.call({
            url: url,
            urlSearchParams: urlSearchParams,
            method: AjaxMethods.GET,
            shouldDownload: true,
        });
    }

    private static async call(params: CallParams) {
        const traceId = uuidv4();
        const urlBase: string = params.url.startsWith('/') ? '' : '/api/crm/';
        const urlSearchParams: string = params.urlSearchParams
            ? '?' + new URLSearchParams(params.urlSearchParams)
            : '';

        const token = Cookies.get(AUTH_COOKIE_NAME);
        const options: Obj = {
            method: params.method,
            headers: {
                Accept: 'application/json',
                'Voigt-Trace-ID': traceId,
            },
        };

        if (token) {
            options.headers.Authorization = `Bearer ${token}`;
        }

        if (params.contentType === 'formData' && params.data) {
            const formData = new FormData();
            Object.entries(params.data).forEach(([key, value]) => {
                formData.append(key, value);
            });
            options.body = formData;
        } else {
            options.headers['Content-Type'] = 'application/json';
            options.body = JSON.stringify(params.data);
        }

        const response = await fetch(
            urlBase + params.url + urlSearchParams,
            options
        );

        if (!response.ok) {
            const body = await response.json();

            switch (response.status) {
                case 400:
                    throw new HttpBadRequestError(traceId, body);
                case 401:
                    if (params.url === 'me' || params.url === 'login') {
                        throw new HttpAuthenticationError(traceId);
                    }
                    throw new HttpAuthenticationTimeoutError(traceId);
                case 403:
                    throw new HttpAuthorizationError(traceId);
                case 404:
                    throw new HttpNotFoundError(traceId);
                case 422:
                    throw new HttpValidationError(traceId, body.errors);
                default:
                    throw new HttpError(
                        traceId,
                        response.status,
                        response.statusText,
                        response
                    );
            }
        }

        if (params.shouldDownload) {
            const contentDisposition = response.headers.get(
                'content-disposition'
            );

            const contentDispositionMatch =
                contentDisposition?.match(/"(.*?)"/);

            const fileName = contentDisposition
                ? contentDispositionMatch
                    ? contentDispositionMatch[1]
                    : contentDisposition.split('filename=')[1].split(';')[0]
                : 'unknown';

            const blob = await response.blob();
            await Ajax.downloadBlob(blob, fileName);
            return;
        }

        return await this.workaroundJsonResponse(response, traceId);
        //return response.json();
    }

    // TODO: Remove this workaround by fixing the server!
    public static async workaroundJsonResponse(
        response: Response,
        traceId: string
    ) {
        const responseText = await response.text();

        if (responseText.length === 0) {
            return null;
        }

        try {
            return JSON.parse(responseText);
        } catch (e) {
            if (responseText === '{}""' || responseText.match(/^{.*}""$/gm)) {
                return {};
            }

            const jsons = /^{.*}({.*})$/gm.exec(responseText);

            if (jsons && jsons[1]) {
                return JSON.parse(jsons[1]);
            } else {
                throw new HttpValidationError(traceId, e);
            }
        }
    }

    public static async file(url: string): Promise<void> {
        const token = Cookies.get(AUTH_COOKIE_NAME);

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        const blob = await response.blob();

        const fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
    }

    private static downloadBlob(blob: Blob, fileName: string) {
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = fileName.toString();
        a.click();
        document.body.appendChild(a);

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }
}
