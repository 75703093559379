import React, { ReactElement, useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import useEntities from '../../../hooks/useEntities';
import { Entity } from '../../../helpers/entities';
import { AutocompleteOption } from '../../../crm';
import AutocompleteInputMultiple from './AutocompleteInputMultiple';

interface Props<T> {
    selectedElements?: T[] | null;
    entity: Entity<T>;
    name?: string;
    minInputLength?: number;
    label?: string;
}

export default function EntityAutocompleteMultiple<T>({
    selectedElements,
    entity,
    name = '',
    minInputLength = 3,
    label = '',
}: Props<T>): ReactElement {
    const { entities } = useEntities<T>(entity);
    const [selected, setSelected] = useState<AutocompleteOption[]>([]);

    useEffect(() => {
        selectedElements &&
            setSelected(entity.autocompleteOptionsMapper(selectedElements));
    }, [selectedElements]);

    const getElements = async (search: string): Promise<AutocompleteOption[]> =>
        entities ? entity.autocompleteOptionsMapper(entities) : [];

    if ((selectedElements?.length && !selected.length) || entities === null) {
        return <TextField disabled value="Lädt..." />;
    }

    return (
        <AutocompleteInputMultiple
            getOptions={getElements}
            name={name || entity.apiBaseUrl}
            label={label || entity.namePlural}
            selectedOptions={selected}
            minInputLength={minInputLength}
        />
    );
}
