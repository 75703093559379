import Webcam from 'react-webcam';
import React, { ReactElement, RefObject } from 'react';
import { useSnackbar } from 'notistack';
import ReactCrop, { Crop } from 'react-image-crop';
import logError from '../errors/logError';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';

interface Props {
    onChange: (newCrop: Crop, newPercentCrop: Crop) => void;
    image: HTMLCanvasElement | null;
    webCamRef: RefObject<Webcam>;
    videoConstraints: MediaTrackConstraints;
    deviceId: string | null;
    crop: Partial<Crop>;
}

const WebcamCrop = ({
    onChange,
    image,
    webCamRef,
    videoConstraints,
    deviceId,
    crop,
}: Props): ReactElement => {
    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();

    const handleUserMediaError = (error: string | DOMException): void => {
        logError(error);
        enqueueSnackbar('Es konnte auf keine Webcam zugegriffen werden!', {
            variant: 'error',
        });
    };

    return (
        <ReactCrop
            onChange={onChange}
            renderComponent={
                image === null && (
                    <Webcam
                        audio={false}
                        ref={webCamRef}
                        screenshotFormat="image/png"
                        videoConstraints={{
                            ...videoConstraints,
                            deviceId: deviceId ?? undefined,
                        }}
                        width="100%"
                        style={{
                            aspectRatio:
                                videoConstraints.aspectRatio?.toString(),
                        }}
                        onUserMediaError={handleUserMediaError}
                        className={classes.webcam}
                    />
                )
            }
            crop={crop}
            src={image?.toDataURL() ?? ''}
            className={classes.crop}
            disabled
        />
    );
};

const useStyles = makeStyles(() =>
    createStyles({
        webcam: {
            display: 'block',
            objectFit: 'cover',
        },
        crop: {
            top: '50%',
            left: '50%',
            width: '100%',
            transform: 'translate(-50%)',
        },
    })
);

export default WebcamCrop;
