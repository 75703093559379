import React, { ReactElement } from 'react';
import { CircularProgress, IconButton } from '@material-ui/core';
import { Report } from '../../../mappers/reportMapper';
import { Link as RouterLink } from 'react-router-dom';
import useEntities from '../../../hooks/useEntities';
import { ReportEntity } from '../../../helpers/entities';
import CrmCard from '../../helpers/CrmCard';
import { List as ListIcon } from '@material-ui/icons';
import ReportList from '../ReportList';

const NewReports = (): ReactElement => {
    const { entities: reports, reloadEntities } = useEntities<Report>(
        ReportEntity,
        {
            limit: 2,
            orderBy: 'created_at',
        }
    );

    return (
        <CrmCard
            title="Neue Berichte"
            titleAction={
                <IconButton size="small" component={RouterLink} to="/reports/">
                    <ListIcon />
                </IconButton>
            }
        >
            {reports !== null ? (
                <ReportList
                    reports={reports}
                    reportChangeHandler={reloadEntities}
                />
            ) : (
                <CircularProgress size={20} />
            )}
        </CrmCard>
    );
};

export default NewReports;
