import React, { ChangeEvent, ReactElement, useContext, useState } from 'react';
import useDialog from '../hooks/useDialog';
import PaginationPage from './helpers/PaginationPage';
import {
    CompetitorEntity,
    ReportEntity,
    UserEntity,
} from '../helpers/entities';
import ReportRows from './modules/Reports/ReportRows';
import ReportDialog from './modules/ReportDialog';
import { GlobalContext } from '../stores/GlobalStore';
import {
    ClickAwayListener,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
} from '@material-ui/core';
import EntityAutocompleteMultiple from './helpers/forms/EntityAutocompleteMultiple';
import { Check, Close, Search } from '@material-ui/icons';
import { useDebounce } from 'react-use';
import { Competitor } from '../mappers/competitorMapper';

const minSearchLength = 3;

const Reports = (): ReactElement => {
    const { dialogOpen, closeDialog, openDialog } = useDialog();
    const [reportDialogId, setReportDialogId] = useState<string | null>(null);
    const [showSearchField, setShowSearchField] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [queryParameters, setQueryParameters] = useState<{
        search_report: string;
    } | null>(null);
    const { setReloadPage } = useContext(GlobalContext);

    const hasSearchQueryRequiredLength = searchQuery.length >= minSearchLength;

    useDebounce(
        () => {
            if (!hasSearchQueryRequiredLength) {
                setQueryParameters(null);
                return;
            }
            setQueryParameters({ search_report: searchQuery });
        },
        300,
        [searchQuery]
    );

    const dialogResponseHandler = () => {
        setReloadPage(true);
    };

    const filters = (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={3}>
                <EntityAutocompleteMultiple
                    entity={UserEntity}
                    name="filter_users"
                    minInputLength={0}
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <EntityAutocompleteMultiple<Competitor>
                    entity={CompetitorEntity}
                    name="filter_competitors"
                    minInputLength={0}
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <IconButton type="submit" color="secondary">
                    <Check />
                </IconButton>
            </Grid>
        </Grid>
    );

    const closeSearch = () => {
        setShowSearchField(false);
    };

    const clearSearch = () => {
        setSearchQuery('');
        closeSearch();
    };

    const openSearch = () => {
        setShowSearchField(true);
    };

    const onChangeSearchQuery = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    return (
        <PaginationPage
            entity={ReportEntity}
            filters={filters}
            additionalQueryParameters={queryParameters}
            buttons={
                showSearchField ? (
                    <ClickAwayListener onClickAway={closeSearch}>
                        <TextField
                            placeholder="Berichte durchsuchen..."
                            name="search"
                            value={searchQuery}
                            onChange={onChangeSearchQuery}
                            type="text"
                            size="small"
                            margin="none"
                            autoComplete="off"
                            fullWidth={false}
                            autoFocus
                            helperText={
                                searchQuery.length > 0 &&
                                !hasSearchQueryRequiredLength
                                    ? `Mind. ${minSearchLength} Zeichen eingeben...`
                                    : null
                            }
                            style={{ flexGrow: 1 }}
                            FormHelperTextProps={{
                                style: {
                                    marginBottom: '-24px',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                },
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search color="disabled" />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={clearSearch}
                                            edge="end"
                                        >
                                            <Close />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </ClickAwayListener>
                ) : (
                    <IconButton onClick={openSearch}>
                        <Search />
                    </IconButton>
                )
            }
        >
            <ReportRows
                openDialog={openDialog}
                setDialogReportId={setReportDialogId}
                searchText={queryParameters?.search_report}
            />

            <ReportDialog
                reportId={reportDialogId}
                dialogOpen={dialogOpen}
                closeDialog={closeDialog}
                responseHandler={dialogResponseHandler}
            />
        </PaginationPage>
    );
};

export default Reports;
