import React, { ReactElement } from 'react';
import { PhoneIphone } from '@material-ui/icons';
import FormattingBase from './FormattingBase';

interface Props {
    mobile: string;
}

const Mobile = ({ mobile }: Props): ReactElement => {
    return (
        <FormattingBase
            label={mobile}
            href={`tel:${mobile.replace(/\D+/g, '')}`}
            title={`${mobile} anrufen`}
            icon={PhoneIphone}
        />
    );
};

export default Mobile;
