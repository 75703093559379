import React, { ReactElement } from 'react';
import { IconButton, SvgIcon, Tooltip } from '@material-ui/core';
import { PhoneDisabled, PhoneEnabled } from '@material-ui/icons';
import Ajax from '../../../helpers/Ajax';
import logError from '../../../errors/logError';
import { useSnackbar } from 'notistack';

interface Props {
    action: string;
    isContactingForbidden: boolean;
    onUpdateHandler: { (): void };
}

const ContactingForbiddenAction = ({
    action,
    isContactingForbidden,
    onUpdateHandler,
}: Props): ReactElement => {
    const { enqueueSnackbar } = useSnackbar();
    const onUpdate = async (): Promise<void> => {
        try {
            await Ajax.post(action, {
                contacting_forbidden: isContactingForbidden,
            });
            enqueueSnackbar(
                `${
                    !isContactingForbidden
                        ? '"Kontakt nicht gewünscht" entfernt'
                        : '"Kontakt nicht gewünscht" gesetzt'
                }`,
                {
                    variant: 'success',
                }
            );
            onUpdateHandler();
        } catch (e) {
            enqueueSnackbar(
                'Fehler beim setzen von "Kontakt nicht gewünscht"',
                {
                    variant: 'error',
                }
            );
            logError(e);
        }
    };
    return (
        <Tooltip
            title={
                !isContactingForbidden
                    ? '"Kein Kontakt gewünscht" deaktivieren'
                    : '"Kein Kontakt gewünscht" aktivieren'
            }
        >
            <IconButton color="secondary" size="medium" onClick={onUpdate}>
                <SvgIcon
                    component={
                        !isContactingForbidden ? PhoneEnabled : PhoneDisabled
                    }
                />
            </IconButton>
        </Tooltip>
    );
};

export default ContactingForbiddenAction;
