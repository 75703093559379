import { getRatioString } from './getRatioString';

export const getContactRecordRatio = (
    monthlyRecords: Obj | null
): string | null => {
    if (monthlyRecords === null) {
        return null;
    }
    const monthlyRecordsArray = Object.values(monthlyRecords);

    const kasko = Number(
        monthlyRecordsArray.reduce((sum, current) => sum + current.kasko, 0)
    );

    const haftpflicht = Number(
        monthlyRecordsArray.reduce(
            (sum, current) => sum + current.haftpflicht,
            0
        )
    );

    if (kasko === haftpflicht) {
        return getRatioString(kasko, haftpflicht, 50, 50);
    } else if (haftpflicht === 0 && kasko >= 1) {
        return getRatioString(kasko, haftpflicht, 100, 0);
    } else if (haftpflicht >= 1 && kasko === 0) {
        return getRatioString(kasko, haftpflicht, 0, 100);
    } else {
        const cases = kasko + haftpflicht;

        const ratioKasko =
            kasko >= Number(haftpflicht)
                ? Math.ceil(((100 / cases) * kasko) / 5) * 5
                : Math.floor(((100 / cases) * kasko) / 5) * 5;

        const ratioHaftpflicht = 100 - ratioKasko;
        return getRatioString(kasko, haftpflicht, ratioKasko, ratioHaftpflicht);
    }
};
