import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom';
import { Dialog } from '@material-ui/core';
import useConfirmationDialog from '../../hooks/useConfirmationDialog';

interface Props {
    dialogOpen: boolean;
    closeDialog: { (): void };
    children: ReactElement | ReactElement[];
    maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false;
    confirmClose?: boolean;
}

const CrmDialog = ({
    dialogOpen,
    closeDialog,
    children,
    maxWidth,
    confirmClose = false,
}: Props): ReactElement | null => {
    const { confirmationDialog, openConfirmation } = useConfirmationDialog({
        children: 'Möchten Sie das Fenster wirklich schließen?',
        onConfirm: closeDialog,
    });

    if (!dialogOpen) {
        return null;
    }

    return ReactDOM.createPortal(
        <>
            {confirmationDialog}
            <Dialog
                fullWidth
                maxWidth={maxWidth || 'md'}
                open={dialogOpen}
                onClose={confirmClose ? openConfirmation : closeDialog}
            >
                {children}
            </Dialog>
        </>,
        document.body
    );
};

export default CrmDialog;
