import React, { ReactElement, useContext, useEffect } from 'react';
import { Box, Grid, LinearProgress, Paper } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import ContactDetails from './modules/ContactDetails/ContactDetails';
import useEntity from '../hooks/useEntity';
import { Rival } from '../mappers/rivalMapper';
import { RivalEntity } from '../helpers/entities';
import AlertPermissionDenied from './helpers/AlertPermissionDenied';
import { GlobalContext } from '../stores/GlobalStore';
import Note from './modules/ContactPerson/Note';
import { RivalContactDetails } from '../mappers/contactDetailMapper';
import Name from './modules/Rival/Name';
import Files from './modules/Files';
import AddressView from './modules/AddressView';

const RivalView = (): ReactElement => {
    const { rivalId } = useParams<{ rivalId?: string }>();
    const { setBackdropOpen } = useContext(GlobalContext);
    const {
        entity: rival,
        reloadEntity: reloadRival,
        requestStatus,
    } = useEntity<Rival>(RivalEntity, rivalId);

    useEffect(() => {
        setBackdropOpen(rival !== null && requestStatus === 'loading');
    }, [requestStatus, rival]);

    if (requestStatus === 'error-forbidden') {
        return <AlertPermissionDenied />;
    }

    if (!rival) {
        return <LinearProgress />;
    }

    return (
        <>
            <Paper>
                <Name rival={rival} reloadRival={reloadRival} />
            </Paper>

            <Box my={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <AddressView
                            apiUrl={
                                RivalEntity.apiBaseUrl +
                                '/' +
                                rival.id +
                                '/address'
                            }
                            address={rival.address}
                            reloadHandler={reloadRival}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <ContactDetails
                            contactDetailFields={RivalContactDetails}
                            contactDetails={rival.contact_details}
                            reloadHandler={reloadRival}
                            contactDetailUpdateUrl={
                                RivalEntity.apiBaseUrl +
                                '/' +
                                rival.id +
                                '/contactdetails'
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Note
                            note={rival.note}
                            apiUrl={RivalEntity.apiBaseUrl + '/' + rival.id}
                            reloadHandler={reloadRival}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Files
                            fileable={rival}
                            apiUrl={
                                RivalEntity.apiBaseUrl +
                                '/' +
                                rival.id +
                                '/files'
                            }
                            reloadHandler={reloadRival}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default RivalView;
