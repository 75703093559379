import mapAddress, { Address } from './addressMapper';
import {
    ContactDetailsHierarchical,
    mapContactDetailsHierarchical,
} from './contactDetailMapper';
import { File, mapFiles } from './fileMapper';

export interface Rival {
    id: string;
    address_id: number | null;
    address: Address;
    contact_details?: ContactDetailsHierarchical | null;
    name: string;
    note: string | null;
    files?: File[];
}

const mapRival = (input: any): Rival => ({
    ...input,
    ...(input.contact_details && {
        contact_details: mapContactDetailsHierarchical(input.contact_details),
    }),
    ...(input.files && {
        files: mapFiles(input.files),
    }),
    address: mapAddress(input.address),
});

export const mapRivals = (input: any): Rival[] => input.map(mapRival);

export default mapRival;
