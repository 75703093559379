import React, { ReactElement } from 'react';
import { Block } from '@material-ui/icons';
import { Chip } from '@material-ui/core';

const DisabledChip = (): ReactElement => {
    return (
        <Chip
            variant="default"
            label="Deaktiviert"
            size="small"
            icon={<Block />}
        />
    );
};

export default DisabledChip;
