import { Office } from '../../../../../../resources/assets/react/entities/Office';

export const matchOffices = (
    officesA?: Office[],
    officesB?: Office[]
): boolean =>
    officesA?.find(
        officeA =>
            officesB?.find(officeB => officeB.unique === officeA.unique) !==
            undefined
    ) !== undefined;
