export interface Cooperation {
    id: number;
    cooperation: string;
}

const mapCooperation = (input: any): Cooperation => ({
    ...input,
});

export const mapCooperations = (input: any): Cooperation[] =>
    input.map(mapCooperation);

export default mapCooperation;
