import { Link } from 'react-router-dom';
import { TableCell, TableRow } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { DecliningCases } from '../../../mappers/DecliningCaseNumbersMapper';

interface Props {
    declinedCase: DecliningCases;
}

const DecliningCaseNumbersRows = ({ declinedCase }: Props): ReactElement => {
    return (
        <TableRow key={declinedCase.contact_id}>
            <TableCell width="30%">
                <Link to={`/contact/${declinedCase.contact_id}`}>
                    {declinedCase.contact_name}
                </Link>
            </TableCell>
            <TableCell>{declinedCase.current_period_cases}</TableCell>
            <TableCell>{declinedCase.reference_period_cases}</TableCell>
            <TableCell>{declinedCase.percent_difference + ' %'}</TableCell>
        </TableRow>
    );
};

export default DecliningCaseNumbersRows;
