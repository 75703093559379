import React, { ReactElement } from 'react';
import { AssignmentTurnedIn, KeyboardArrowLeft } from '@material-ui/icons';
import { Box, Button, Typography, useTheme } from '@material-ui/core';
import { Link } from 'react-router-dom';

const ReportDoneNotification = (): ReactElement => {
    return (
        <Box mx="auto" my="100px" maxWidth="500px">
            <Box
                display="grid"
                alignItems="center"
                gridTemplateColumns="auto 1fr"
                gridGap={useTheme().spacing(1)}
                mb={1}
            >
                <AssignmentTurnedIn color="primary" />
                <Typography variant="h1">Erledigt</Typography>
            </Box>
            <Typography gutterBottom>
                Ihre Wiedervorlage wurde als erledigt markiert.
            </Typography>
            <Box clone mt={4}>
                <Button
                    component={Link}
                    to="/"
                    color="secondary"
                    variant="contained"
                    startIcon={<KeyboardArrowLeft />}
                >
                    Zurück zum Dashboard
                </Button>
            </Box>
        </Box>
    );
};

export default ReportDoneNotification;
