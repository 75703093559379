import HttpBaseError from './HttpBaseError';

export default class HttpNotFoundError extends HttpBaseError {
    constructor(traceId: string) {
        super(traceId, 'Not found');
        if (Error?.captureStackTrace) {
            Error.captureStackTrace(this, HttpNotFoundError);
        }
        this.name = 'HttpNotFoundError';
        Object.setPrototypeOf(this, HttpNotFoundError.prototype);
    }
}
