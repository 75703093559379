import { Report } from '../mappers/reportMapper';
import dayjs from 'dayjs';

export type MonthlyRecords = {
    [date: string]: { haftpflicht: number; kasko: number };
};

export function getZeroFilledRecords(
    monthlyRecords: MonthlyRecords,
    reports: Report[]
): MonthlyRecords {
    if (!reports.length) {
        return monthlyRecords;
    }

    const firstReportDate = dayjs(reports[reports.length - 1].date).startOf(
        'month'
    );
    let firstRecordDate = dayjs(Object.keys(monthlyRecords)[0]).startOf(
        'month'
    );
    let filledRecords = monthlyRecords;

    while (firstRecordDate.isAfter(firstReportDate)) {
        firstRecordDate = firstRecordDate.subtract(1, 'month');

        const newRecord = {
            [firstRecordDate.format('YYYY-MM-01')]: {
                haftpflicht: 0,
                kasko: 0,
            },
        };

        filledRecords = { ...newRecord, ...filledRecords };
    }

    return filledRecords;
}
