import React, { ReactElement, useContext } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { EntityContext } from '../../../helpers/entities';
import { Rival } from '../../../mappers/rivalMapper';

const RivalRows = (): ReactElement => {
    const { entities: rivals } = useContext(EntityContext);

    return (
        <>
            {rivals &&
                rivals.map((rival: Rival) => (
                    <TableRow key={rival.id}>
                        <TableCell>
                            <Link to={`/rival/${rival.id}`}>{rival.name}</Link>
                        </TableCell>
                        <TableCell>{rival.address.address}</TableCell>
                        <TableCell>{rival.address.postcode}</TableCell>
                        <TableCell>{rival.address.city}</TableCell>
                    </TableRow>
                ))}
        </>
    );
};

export default RivalRows;
