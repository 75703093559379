import React, { ReactElement } from 'react';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import useConfirmationDialog from '../../../hooks/useConfirmationDialog';

interface Props {
    editClose(): void;
    size?: 'small' | 'medium';
    edge?: 'end' | 'start';
    noCloseConfirmation?: boolean;
}

const EditCloseAction = ({
    editClose,
    size = 'small',
    edge,
    noCloseConfirmation,
}: Props): ReactElement => {
    const { confirmationDialog, openConfirmation } = useConfirmationDialog({
        onConfirm: editClose,
    });

    return (
        <>
            <IconButton
                size={size}
                onClick={noCloseConfirmation ? editClose : openConfirmation}
                edge={edge}
            >
                <Close />
            </IconButton>
            {confirmationDialog}
        </>
    );
};

export default EditCloseAction;
