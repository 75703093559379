import React, { ReactElement, useState } from 'react';
import { OfficeEntity } from '../helpers/entities';
import OfficeRows from './modules/Offices/OfficeRows';
import PaginationPage from './helpers/PaginationPage';
import useDialog from '../hooks/useDialog';
import OfficeUsersDialog from './modules/OfficeUsersDialog';

const Offices = (): ReactElement => {
    const { dialogOpen, closeDialog, openDialog } = useDialog();
    const [officeDialogUnique, setOfficeDialogUnique] = useState<string | null>(
        null
    );
    const [officeDialogName, setOfficeDialogName] = useState<string | null>(
        null
    );

    const helpers = [
        {
            heading: 'Zugriffsbeschränkt:',
            text: `Kontakte aus zugriffsbeschränkten Niederlassungen können nur von Administratoren eingesehen werden.
                Alle anderen Kontakte können von jedem Benutzer über die Suche gefunden und angezeigt werden.`,
        },
        {
            heading: 'Vertriebsmitarbeiter:',
            text: `Ist einer Niederlassung ein Vertriebsmitarbeiter zugeordnet, bekommt er
                alle Wiedervorlagen-E-Mails der Kontakte dieser Niederlassung (Nur von Kontakten, die die
                Niederlassung als Hauptstandort eingetragen haben). Sofern kein Mitarbeiter zugewiesen ist, gehen die E-Mails an
                den jeweiligen Verfasser des Berichtes.`,
        },
    ];

    return (
        <PaginationPage
            entity={OfficeEntity}
            withRelations={['crmMembers', 'assignedCrmUser']}
            helpers={helpers}
        >
            <OfficeRows
                openDialog={openDialog}
                setDialogOfficeUnique={setOfficeDialogUnique}
                setDialogOfficeName={setOfficeDialogName}
            />
            <OfficeUsersDialog
                officeUnique={officeDialogUnique}
                officeName={officeDialogName}
                dialogOpen={dialogOpen}
                closeDialog={closeDialog}
            />
        </PaginationPage>
    );
};

export default Offices;
