import React, { ReactElement, useEffect } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import Cookies from 'js-cookie';
import { AUTH_COOKIE_NAME } from '../stores/AuthStore';
import { homeUrl } from '../helpers/createUrlHelper';

const Unauthenticated = (): ReactElement => {
    const resetUser = (): void => {
        Cookies.remove(AUTH_COOKIE_NAME);
    };

    useEffect(() => {
        resetUser();
    }, []);

    const onClickReload = () => {
        window.location.replace(homeUrl());
    };

    return (
        <Box mx="auto" maxWidth="500px" margin="50px">
            <Typography gutterBottom variant="h1">
                Nicht Authentifiziert
            </Typography>
            <Typography gutterBottom>
                Leider konnten wir Sie nicht authentifizieren.
                <br />
                Möglicherweise waren Sie zu lange inaktiv <br />
                und Ihre Sitzung ist abgelaufen.
            </Typography>
            <Typography gutterBottom>
                Bitte melden Sie sich erneut an.
            </Typography>
            <Button onClick={onClickReload} color="primary">
                Zurück zur Anmeldung
            </Button>
        </Box>
    );
};

export default Unauthenticated;
