import React, { ChangeEvent, ReactElement } from 'react';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import { getEmailRegex } from '../../../helpers/emailRegex';

interface Props {
    name: string;
    label: string;
    placeholder?: string;
    helperText?: string;
    type?: string;
}

const AutoCompleteInputFree = ({
    name,
    label,
    placeholder,
    helperText = '',
    type,
}: Props): ReactElement => {
    const { setFieldValue, errors, values, setFieldError } =
        useFormikContext<any>();

    const textInputName = `${name}_textfield_input`;

    const emailValidation = (email: string): boolean => {
        if (getEmailRegex.test(email)) {
            return true;
        }
        setFieldError(name, 'E-mail-Adresse ungültig');
        return false;
    };

    const onChangeAutoComplete = (
        event: ChangeEvent<any>,
        values: any[]
    ): void => {
        if (type === 'email' && values.length >= 1) {
            if (!emailValidation(values[values.length - 1])) {
                return;
            }
        }
        setFieldValue(name, values);
        setFieldValue(textInputName, '');
    };

    const hasErrors = !!errors[name] || !!errors[textInputName];

    return (
        <Autocomplete
            multiple
            freeSolo
            onChange={onChangeAutoComplete}
            options={[]}
            autoHighlight
            ChipProps={{
                color: 'secondary',
            }}
            value={values[name]}
            renderInput={(params: AutocompleteRenderInputParams) => {
                return (
                    <Field
                        {...params}
                        component={TextField}
                        error={hasErrors}
                        name={textInputName}
                        label={label}
                        type={type}
                        placeholder={placeholder}
                        helperText={
                            hasErrors
                                ? errors[name] ?? errors[textInputName]
                                : helperText
                        }
                    />
                );
            }}
        />
    );
};

export default AutoCompleteInputFree;
