import dayjs, { Dayjs } from 'dayjs';

export enum ContractType {
    RentalCarTool = 'rental-car-tool',
    Kasko = 'kasko',
    Other = 'other',
}

export interface Contract {
    id: string;
    type: ContractType;
    start_at: Dayjs;
    end_at: Dayjs | null;
    comment: string;
    uri: string;
    earliest_termination_at: Dayjs | null;
}

export const mapContract = (input: any): Contract => ({
    id: input.id,
    type: input.type as ContractType,
    start_at: dayjs(input.start_at),
    end_at: input.end_at ? dayjs(input.end_at) : null,
    comment: input.comment ?? '',
    uri: input.uri,
    earliest_termination_at: input.earliest_termination_at
        ? dayjs(input.earliest_termination_at)
        : null,
});

export const mapContracts = (inputs: any[]): Contract[] =>
    inputs.map(input => mapContract(input));

export const contractTypeLabel = (type: ContractType): string => {
    const labels = {
        [ContractType.RentalCarTool]: 'Mietwagen-Tool',
        [ContractType.Kasko]: 'Kasko',
        [ContractType.Other]: 'Sonstige',
    };
    return labels[type] ?? labels[ContractType.Other];
};
