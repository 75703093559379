import React, { ReactElement } from 'react';
import FormattingBase from './FormattingBase';
import { Launch } from '@material-ui/icons';

interface Props {
    url: string;
}

const Url = ({ url }: Props): ReactElement => {
    return (
        <FormattingBase
            icon={Launch}
            label={url}
            href={url.startsWith('http') ? url : '//' + url}
            title={`${url} aufrufen`}
            linkProps={{
                rel: 'noreferrer noopener',
                target: '_blank',
            }}
        />
    );
};

export default Url;
