import { Contact } from '../mappers/contactMapper';

export const calculateAverageOfCoordinates = (
    contacts: Contact[] | null
): { lat: number; lng: number } => {
    if (!contacts || contacts.length === 0) {
        return { lat: 0, lng: 0 };
    }
    const coordinateSum: {
        lat: number;
        lng: number;
        length: number;
    } = contacts.reduce(
        (coordinates, contact) =>
            contact.address?.latitude && contact.address?.longitude
                ? {
                      lat: coordinates.lat + contact.address.latitude,
                      lng: coordinates.lng + contact.address.longitude,
                      length: coordinates.length + 1,
                  }
                : coordinates,
        {
            lat: 0,
            lng: 0,
            length: 0,
        }
    );
    return {
        lat: coordinateSum.lat / coordinateSum.length,
        lng: coordinateSum.lng / coordinateSum.length,
    };
};
