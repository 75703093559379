import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import dayjs from 'dayjs';
import { defaults } from 'react-chartjs-2';
import 'dayjs/locale/de';

dayjs.locale('de');

defaults.plugins.tooltip.position = 'nearest';
defaults.plugins.tooltip.intersect = false;
defaults.plugins.tooltip.mode = 'index';
defaults.elements.line.tension = 0;
defaults.elements.line.borderWidth = 2;

ReactDOM.render(<App />, document.getElementById('root-crm'));
