import React, { ReactElement } from 'react';
import {
    createStyles,
    TableCell,
    TableCellProps,
    TableHead,
    TableRow,
    TableSortLabel,
} from '@material-ui/core';
import { Order } from '../../helpers/getStableSortMap';
import { makeStyles } from '@material-ui/core/styles';

export interface HeadCell<T> {
    id: keyof T;
    label: string;
    alignment?: TableCellProps['align'];
}

interface Props<T> {
    onRequestSort: (
        event: React.MouseEvent<unknown>,
        property: keyof T
    ) => void;
    headCells: HeadCell<T>[];
    order: Order;
    orderBy: keyof T;
}

const UniqueTableHeadCells = <T,>({
    onRequestSort,
    headCells,
    order,
    orderBy,
}: Props<T>): ReactElement => {
    const classes = useStyles();
    const createSortHandler =
        (property: keyof T) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id as string}
                        align={headCell.alignment}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            classes={{
                                icon:
                                    orderBy === headCell.id
                                        ? classes.active
                                        : classes.inactive,
                            }}
                            active={orderBy === headCell.id}
                            direction={
                                orderBy === headCell.id ? order : Order.ASC
                            }
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};
const useStyles = makeStyles(() =>
    createStyles({
        active: {
            opacity: '100%',
        },
        inactive: {
            opacity: '25%',
        },
    })
);
export default UniqueTableHeadCells;
