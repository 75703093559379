import { DialogTitle, Paper, Typography } from '@material-ui/core';
import EditCloseAction from './helpers/actions/EditCloseAction';
import React, { ReactElement, useState } from 'react';
import ContactPersonAddDialog from './modules/Contact/ContactPersonAddDialog';
import {
    CovveContact,
    CovveContactPerson,
} from '../helpers/processCovveResponse';
import ContactAddDialog from './modules/ContactAddDialog';
import BusinessCardScanContent from './modules/BusinessCard/BusinessCardScanContent';

interface Props {
    closeDialog: () => void;
    contactId?: string;
    responseHandler?(): void;
}

const BusinessCardScanDialog = ({
    closeDialog,
    contactId,
    responseHandler,
}: Props): ReactElement => {
    const [isScanned, setIsScanned] = useState<boolean>(false);
    const [isContactPerson] = useState<boolean>(!!contactId);
    const [scannedValues, setScannedValues] = useState<
        CovveContact | CovveContactPerson
    >();

    return (
        <Paper>
            {!isScanned ? (
                <>
                    <DialogTitle>
                        <Typography component="h2">
                            Visitenkarte Scannen
                        </Typography>
                        <EditCloseAction
                            editClose={closeDialog}
                            edge="end"
                            noCloseConfirmation
                        />
                    </DialogTitle>
                    <>
                        <BusinessCardScanContent
                            setIsScanned={setIsScanned}
                            setScannedValues={setScannedValues}
                            contactId={contactId}
                        />
                    </>
                </>
            ) : isContactPerson && responseHandler ? (
                <ContactPersonAddDialog
                    closeDialog={closeDialog}
                    contactId={contactId}
                    responseHandler={responseHandler}
                    scannedValues={scannedValues as CovveContactPerson}
                />
            ) : (
                <ContactAddDialog
                    closeDialog={closeDialog}
                    scannedValues={scannedValues as CovveContact}
                />
            )}
        </Paper>
    );
};

export default BusinessCardScanDialog;
