import React, { ReactElement, useContext } from 'react';
import { AuthContext } from '../../stores/AuthStore';
import { Redirect, Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

interface Props {
    path: string;
    permission: string;
    component:
        | React.ComponentType<RouteComponentProps<any>>
        | React.ComponentType<any>;
}

const RouteRestricted = ({
    path,
    component,
    permission,
}: Props): ReactElement => {
    const { userCan } = useContext<AuthContext>(AuthContext);

    if (!userCan(permission)) {
        console.error('Permission denied! Redirecting...');
        return <Redirect to="/" />;
    }

    return <Route exact path={path} component={component} />;
};

export default RouteRestricted;
