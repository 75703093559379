export interface AccidentConsultant {
    id: number;
    accident_consultant: string;
}

const mapAccidentConsultant = (input: any): AccidentConsultant => ({
    ...input,
});

export const mapAccidentConsultants = (input: any): AccidentConsultant[] =>
    input.map(mapAccidentConsultant);

export default mapAccidentConsultant;
