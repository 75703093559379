import React, { ReactElement } from 'react';
import { Box, createStyles, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CrmTheme } from '../../theme';
import { Link } from 'react-router-dom';

interface Props {
    title?: any;
    subtitle?: string | null;
    subtitleLink?: string | null;
    disableTypography?: boolean;
    buttons?: any;
}

const CrmToolbar = ({
    title,
    subtitle,
    subtitleLink,
    disableTypography,
    buttons,
}: Props): ReactElement => {
    const classes = useStyles();

    return (
        <Toolbar>
            {disableTypography ? (
                <Box marginBottom={1} className={classes.grow}>
                    {title}
                </Box>
            ) : (
                <Typography
                    className={classes.grow}
                    variant="h1"
                    component="h1"
                >
                    {title}
                    {subtitle && (
                        <Typography variant="body2" color="textSecondary">
                            {subtitleLink ? (
                                <Link
                                    className={classes.subtitleLink}
                                    to={subtitleLink}
                                >
                                    {subtitle}
                                </Link>
                            ) : (
                                subtitle
                            )}
                        </Typography>
                    )}
                </Typography>
            )}

            {buttons}
        </Toolbar>
    );
};

export default CrmToolbar;

const useStyles = makeStyles((theme: CrmTheme) =>
    createStyles({
        grow: {
            flexGrow: 1,
        },
        subtitleLink: {
            color: 'inherit',
            textDecorationColor: '#DDD',
        },
    })
);
