import React, { ReactElement, useState } from 'react';
import { Form, Formik, FormikErrors, FormikHelpers } from 'formik';
import Ajax, { ContentType } from '../../../helpers/Ajax';
import { useSnackbar } from 'notistack';
import { ObjectSchema } from 'yup';
import HttpValidationError from '../../../errors/HttpValidationError';
import logError from '../../../errors/logError';
import { isErrorInstance } from '../../../errors/error-helpers';

interface Props {
    children: any;
    action: string;
    initialValues: {
        [key: string]: string | number | null | string[] | boolean;
    };
    validationSchema?: ObjectSchema<any> | ObjectSchema<any>[];
    additionalValues?: { [key: string]: string | number | null };

    responseHandler?(response?: any): void;

    errorMessage?: string;
    contentType?: ContentType;
    enableReinitialize?: boolean;
    preSubmitFunction?: ((values: any) => Promise<any>) | null;
}

const FormBuilder = ({
    children,
    action,
    initialValues,
    additionalValues,
    responseHandler,
    errorMessage,
    validationSchema,
    contentType,
    enableReinitialize = false,
    preSubmitFunction = null,
}: Props): ReactElement => {
    const { enqueueSnackbar } = useSnackbar();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmit = async (values: any, { setErrors }: FormikHelpers<any>) => {
        if (isSubmitting) {
            console.log('double submit blocked!');
            return;
        }

        setIsSubmitting(true);
        setErrors({});

        try {
            const response = await Ajax.post<any>(
                action,
                {
                    ...values,
                    ...additionalValues,
                    ...(preSubmitFunction
                        ? await preSubmitFunction(values)
                        : {}),
                },
                contentType
            );

            setIsSubmitting(false);

            responseHandler && responseHandler(response);
        } catch (e) {
            setIsSubmitting(false);
            enqueueSnackbar(errorMessage ?? 'Formularfehler!', {
                variant: 'error',
            });

            if (isErrorInstance(e, HttpValidationError)) {
                setErrors((e as HttpValidationError<FormikErrors<any>>).errors);
                return;
            }

            logError(e);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize={enableReinitialize}
        >
            <Form>{children}</Form>
        </Formik>
    );
};

export default FormBuilder;

export interface FormBuilderFieldProps {
    name: string;
    label: string;
    required?: boolean;
}
