export interface ReportCopy {
    id: number;
    email: string;
    send_at: Date | null;
    type: 'internal' | 'external';
}

const mapReportCopy = (input: any): ReportCopy => ({
    id: input.id,
    email: input.email,
    send_at: input.send_at ? new Date(input.send_at) : null,
    type: input.type,
});

export const mapReportCopies = (inputs: any): ReportCopy[] =>
    inputs.map((input: any) => mapReportCopy(input));

export default mapReportCopy;
