import { Box } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { ContactPerson } from '../../../mappers/contactPersonMapper';
import { Link } from 'react-router-dom';

interface Props {
    contactPerson: ContactPerson;
    useSortableName?: boolean;
}

const Short = ({ contactPerson, useSortableName }: Props): ReactElement => {
    return (
        <Box>
            <Link
                to={`/contact_person/${contactPerson.id}`}
                style={{
                    textDecoration: contactPerson.is_archived
                        ? 'line-through'
                        : 'none',
                    whiteSpace: 'nowrap',
                }}
            >
                {useSortableName
                    ? contactPerson.nameSortable
                    : contactPerson.name}
            </Link>
        </Box>
    );
};

export default Short;
