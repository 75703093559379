import HttpBaseError from './HttpBaseError';

export default class HttpAuthenticationError extends HttpBaseError {
    constructor(traceId: string) {
        super(traceId, 'Unauthorized');
        if (Error?.captureStackTrace) {
            Error.captureStackTrace(this, HttpAuthenticationError);
        }
        this.name = 'HttpAuthenticationError';
        Object.setPrototypeOf(this, HttpAuthenticationError.prototype);
    }
}
