import HttpBaseError from './HttpBaseError';

export default class HttpBadRequestError extends HttpBaseError {
    constructor(traceId: string, message: string) {
        super(traceId, message);
        if (Error?.captureStackTrace) {
            Error.captureStackTrace(this, HttpBadRequestError);
        }
        this.name = 'HttpBadRequestError';
        Object.setPrototypeOf(this, HttpBadRequestError.prototype);
    }
}
