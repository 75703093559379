import HttpBaseError from './HttpBaseError';

export default class HttpError extends HttpBaseError {
    public readonly statusCode: number;
    public readonly statusText: string;
    private readonly response: Response;

    constructor(
        traceId: string,
        statusCode: number,
        statusText: string,
        response: Response
    ) {
        super(traceId, 'Server error');
        this.statusCode = statusCode;
        this.statusText = statusText;
        this.response = response;
    }

    public async getResponse() {
        return await this.response.json();
    }
}
