import React from 'react';
import { getStableSortMap, Order } from '../helpers/getStableSortMap';

const useUniqueTableHeadCells = <T>(
    orderByInit: keyof T,
    startOrder?: Order
): {
    handleRequestSort: (
        event: React.MouseEvent<unknown>,
        property: keyof T
    ) => void;
    orderBy: keyof T;
    getStableSortMap: (array: T[], order: Order, orderBy: keyof T) => T[];
    order: Order;
} => {
    const [order, setOrder] = React.useState<Order>(startOrder ?? Order.DESC);
    const [orderBy, setOrderBy] = React.useState<keyof T>(orderByInit);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof T
    ) => {
        const isAsc = orderBy === property && order === Order.ASC;
        setOrder(isAsc ? Order.DESC : Order.ASC);
        setOrderBy(property);
    };

    return { order, orderBy, handleRequestSort, getStableSortMap };
};

export default useUniqueTableHeadCells;
