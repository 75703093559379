import ValidationError from '../ValidationError';

export default class CallGenderizeIoValidationError extends ValidationError {
    constructor(message: string) {
        super(message);
        if (Error?.captureStackTrace) {
            Error.captureStackTrace(this, CallGenderizeIoValidationError);
        }
        this.name = 'CallGenderizeIoValidationError';
        Object.setPrototypeOf(this, CallGenderizeIoValidationError.prototype);
    }
}
