export enum ContactDetailType {
    email = 'email',
    web = 'web',
    fax = 'fax',
    phone = 'phone',
    mobile = 'mobile',
}

export const ContactPersonContactDetails = [
    ContactDetailType.phone,
    ContactDetailType.mobile,
    ContactDetailType.email,
    ContactDetailType.fax,
];

export const ContactContactDetails = [
    ContactDetailType.phone,
    ContactDetailType.email,
    ContactDetailType.fax,
    ContactDetailType.web,
];

export const RivalContactDetails = ContactContactDetails;

export const contactDetailOptions: {
    [key in ContactDetailType]: {
        label: string;
        valueLabel: string;
    };
} = {
    phone: {
        label: 'Telefon',
        valueLabel: 'Telefonnummer',
    },
    fax: {
        label: 'Fax',
        valueLabel: 'Faxnummer',
    },
    email: {
        label: 'E-Mail',
        valueLabel: 'E-Mail-Adresse',
    },
    web: {
        label: 'Web',
        valueLabel: 'Webseite',
    },
    mobile: {
        label: 'Mobilnummer',
        valueLabel: 'Mobilnummer',
    },
};

export interface ContactDetail {
    id: string;
    key: ContactDetailType;
    label: string;
    valueLabel: string;
    value: string;
    description: string;
}

export type ContactDetailsHierarchical = {
    [key in ContactDetailType]: ContactDetail[];
};

const mapContactDetail = (input: any): ContactDetail => ({
    ...input,
});

export const mapContactDetails = (input: any): ContactDetail[] =>
    input.map(mapContactDetail);

export const mapContactDetailsHierarchical = (
    input: any
): ContactDetailsHierarchical | null => {
    const returnObj: ContactDetailsHierarchical =
        {} as ContactDetailsHierarchical;
    const mappedContactDetails: ContactDetail[] = input.map(mapContactDetail);

    if (!mappedContactDetails.length) {
        return null;
    }

    mappedContactDetails.forEach(contactDetail => {
        returnObj[contactDetail.key]
            ? returnObj[contactDetail.key].push(contactDetail)
            : (returnObj[contactDetail.key] = [contactDetail]);
    });

    return returnObj;
};

export const getFirstContactDetailOfType = (
    contactDetails: ContactDetail[],
    type: ContactDetailType
): string => contactDetails.find(detail => detail.key === type)?.value ?? '';

export default mapContactDetail;
