import React, { ReactElement } from 'react';
import { Grid, Typography } from '@material-ui/core';
import NewContacts from './modules/Dashboard/NewContacts';
import NewContactPersons from './modules/Dashboard/NewContactPersons';
import NewReports from './modules/Dashboard/NewReports';
import Notifications from './modules/Dashboard/Notifications';
import Statistics from './modules/Dashboard/Statistics';
import Rivals from './modules/Dashboard/Rivals';

const Dashboard = (): ReactElement => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6}>
                        <NewContacts />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <NewContactPersons />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Rivals />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <Notifications />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <Statistics />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <NewReports />
            </Grid>
            <Grid item xs={12}>
                <Typography color="textSecondary" variant="body2" align="left">
                    Build: {window.Voigt.version}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default Dashboard;
