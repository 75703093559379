import { IEmpfehler } from '../../../../../../resources/assets/react/types';

const mapEmpfehler = (input: any): IEmpfehler => ({
    ...input,
});

export const mapEmpfehlers = (input: any): IEmpfehler[] =>
    input.map(mapEmpfehler);

export default mapEmpfehler;
