import { Button, DialogActions } from '@material-ui/core';
import React from 'react';

interface Props {
    image: HTMLCanvasElement | null;
    isLoading: boolean;
    reScan: () => void;
    submitScan: () => void;
    scan: () => void;
}

const BusinessCardScanDialogActions = ({
    image,
    isLoading,
    reScan,
    submitScan,
    scan,
}: Props) => {
    return (
        <DialogActions>
            {image ? (
                <>
                    <Button
                        type="submit"
                        color="primary"
                        onClick={reScan}
                        disabled={isLoading}
                    >
                        Erneut Scannen
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        onClick={submitScan}
                        disabled={isLoading}
                    >
                        Scan Akzeptieren
                    </Button>
                </>
            ) : (
                <Button type="submit" color="primary" onClick={scan}>
                    Scannen
                </Button>
            )}
        </DialogActions>
    );
};

export default BusinessCardScanDialogActions;
