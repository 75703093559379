export const getLastMonthNameAsString = (date?: Date): string => {
    const today = date ?? new Date();

    return new Intl.DateTimeFormat('de', {
        month: 'long',
    }).format(new Date(today.getFullYear(), today.getMonth() - 1, 1));
};

export const getPenultimateMonthNameAsString = (date?: Date): string => {
    const today = date ?? new Date();

    return new Intl.DateTimeFormat('de', {
        month: 'long',
    }).format(new Date(today.getFullYear(), today.getMonth() - 2, 1));
};

export const getMonthBeforeSevenMonthsNameAsString = (date?: Date): string => {
    const today = date ?? new Date();

    return new Intl.DateTimeFormat('de', {
        month: 'long',
    }).format(new Date(today.getFullYear(), today.getMonth() - 7, 1));
};
