import React, { ReactElement } from 'react';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Typography,
} from '@material-ui/core';
import FormBuilder from '../../helpers/forms/FormBuilder';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import FileField, { defaultValue } from '../../helpers/forms/FileField';
import {
    Contract,
    ContractType,
    contractTypeLabel,
} from '../../../mappers/contractMapper';
import EditCloseAction from '../../helpers/actions/EditCloseAction';

interface Props {
    action: string;
    closeDialog: { (): void };
    responseHandler(): void;
    contract?: Contract | null;
}

const ContractFormDialog = ({
    closeDialog,
    action,
    responseHandler,
    contract = null,
}: Props): ReactElement => {
    const title = `Vertrag ${contract?.id ? 'bearbeiten' : 'hinterlegen'}`;
    return (
        <FormBuilder
            action={action}
            contentType="formData"
            initialValues={{
                type: contract?.type ?? '',
                comment: contract?.comment ?? '',
                start_at: contract?.start_at?.format('YYYY-MM-DD') ?? '',
                end_at: contract?.end_at?.format('YYYY-MM-DD') ?? '',
                earliest_termination_at:
                    contract?.earliest_termination_at?.format('YYYY-MM-DD') ??
                    '',
                file: defaultValue,
            }}
            responseHandler={responseHandler}
            errorMessage="Fehler beim Speichern des Vertrags!"
        >
            <DialogTitle>
                <Typography component="h2">{title}</Typography>
                <EditCloseAction editClose={closeDialog} edge="end" />
            </DialogTitle>
            <DialogContent dividers>
                <Field
                    component={TextField}
                    name="type"
                    label="Vertragstyp"
                    autoFocus
                    required
                    select
                >
                    {Object.values(ContractType).map(type => (
                        <MenuItem value={type} key={type}>
                            {contractTypeLabel(type)}
                        </MenuItem>
                    ))}
                </Field>
                <Field
                    component={TextField}
                    type="date"
                    name="start_at"
                    label="Vertragsbeginn"
                    InputLabelProps={{ shrink: true }}
                    required
                />
                <FileField
                    name="file"
                    label={
                        contract?.id ? 'Vertrag ersetzen' : 'Vertrag hochladen'
                    }
                />
                <Field
                    component={TextField}
                    type="date"
                    name="end_at"
                    label="Gekündigt zum"
                    InputLabelProps={{ shrink: true }}
                />
                <Field
                    component={TextField}
                    type="date"
                    name="earliest_termination_at"
                    label="Frühester Kündigungstermin"
                    InputLabelProps={{ shrink: true }}
                />
                <Field
                    component={TextField}
                    name="comment"
                    label="Notiz"
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button type="submit" color="primary">
                    {title}
                </Button>
            </DialogActions>
        </FormBuilder>
    );
};

export default ContractFormDialog;
