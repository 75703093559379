import React, { ReactElement } from 'react';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography,
} from '@material-ui/core';
import useDialog from './useDialog';
import CrmDialog from '../components/helpers/CrmDialog';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
    children?: string | ReactElement;
    confirmationButtonText?: string;
    confirmationButtonColor?: 'error' | 'default';
    onConfirm(): void;
}

const useConfirmationDialog = ({
    onConfirm,
    confirmationButtonText = 'Ok',
    confirmationButtonColor = 'default',
    children = 'Möchten Sie die Bearbeitung wirklich Abbrechen ohne zu Speichern?',
}: Props): {
    confirmationDialog: ReactElement;
    openConfirmation: { (): void };
} => {
    const { closeDialog, dialogOpen, openDialog } = useDialog();
    const classes = useStyles();

    const confirmationContinue = () => {
        closeDialog();
        onConfirm();
    };

    const confirmationDialog = (
        <CrmDialog
            maxWidth="xs"
            closeDialog={closeDialog}
            dialogOpen={dialogOpen}
        >
            <DialogTitle>
                <Typography variant="h2">Bestätigung erforderlich</Typography>
                <IconButton onClick={closeDialog} size="small" edge="end">
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>{children}</DialogContent>
            <DialogActions>
                <Grid container justify="space-between">
                    <Grid item>
                        <Button type="button" autoFocus onClick={closeDialog}>
                            Abbrechen
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            type="button"
                            color="secondary"
                            onClick={confirmationContinue}
                            className={
                                confirmationButtonColor !== 'default'
                                    ? classes[confirmationButtonColor]
                                    : undefined
                            }
                        >
                            {confirmationButtonText}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </CrmDialog>
    );
    return { confirmationDialog, openConfirmation: openDialog };
};

export default useConfirmationDialog;

const useStyles = makeStyles(theme => ({
    error: {
        color: theme.palette.error.main,
    },
}));
