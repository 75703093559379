import HttpNotFoundError from '../errors/HttpNotFoundError';
import { useHistory } from 'react-router-dom';
import { isErrorInstance } from '../errors/error-helpers';

const useError = () => {
    const history = useHistory();

    const redirectIfNotFoundException = (error: unknown): boolean => {
        if (isErrorInstance(error, HttpNotFoundError)) {
            history.replace('/404');
            return true;
        }
        return false;
    };

    return { redirectIfNotFoundException };
};

export default useError;
