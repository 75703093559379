import React, { ReactElement, useMemo } from 'react';
import { DialogTitle, Typography } from '@material-ui/core';
import FormBuilder from '../../helpers/forms/FormBuilder';
import CrmDialog from '../../helpers/CrmDialog';
import * as yup from 'yup';
import ReportDialogForm from './ReportDialogForm';
import { Contact } from '../../../mappers/contactMapper';
import EditCloseAction from '../../helpers/actions/EditCloseAction';
import dayjs from 'dayjs';

interface Props {
    dialogOpen: boolean;
    closeDialog(): void;
    contact: Contact;
    responseHandler(): void;
}

const ReportAddDialog = ({
    dialogOpen,
    closeDialog,
    contact,
    responseHandler,
}: Props): ReactElement => {
    const storageKey = `${contact.id}-new_report`;

    const initValues = useMemo(() => {
        const storedValuesJson = localStorage.getItem(storageKey);

        if (storedValuesJson === null) {
            return {
                date: null,
                subject: '',
                type: '',
                report: '',
                notification_date: null,
                contact_persons: [],
                internal_participants: [],
                internal: [],
                external: [],
                competitors: [],
            };
        }

        return JSON.parse(storedValuesJson);
    }, [dialogOpen, storageKey]);

    const storeValues = (values: any) => {
        const valuesJSON = JSON.stringify({
            ...values,
            date: values.date ? dayjs(values.date).format('YYYY-MM-DD') : null,
            notification_date: values.notification_date
                ? dayjs(values.notification_date).format('YYYY-MM-DD')
                : null,
            done: values.done ? dayjs(values.done).format('YYYY-MM-DD') : null,
        });

        localStorage.setItem(storageKey, valuesJSON);
    };

    const handleResponse = () => {
        localStorage.removeItem(storageKey);
        responseHandler();
    };

    return (
        <CrmDialog
            dialogOpen={dialogOpen}
            closeDialog={closeDialog}
            maxWidth="md"
            confirmClose
        >
            <FormBuilder
                action={'contacts/' + contact.id + '/reports'}
                initialValues={initValues}
                validationSchema={validationSchema}
                responseHandler={handleResponse}
                errorMessage="Fehler beim Speichern des Berichtes!"
            >
                <DialogTitle>
                    <Typography component="h2">Bericht hinzufügen</Typography>
                    <EditCloseAction editClose={closeDialog} edge="end" />
                </DialogTitle>
                <ReportDialogForm
                    contact={contact}
                    storeValues={storeValues}
                    newReport
                />
            </FormBuilder>
        </CrmDialog>
    );
};

export default ReportAddDialog;

const validationSchema = yup.object().shape({
    external: yup
        .array()
        .of(
            yup
                .string()
                .email(
                    params =>
                        `"${params.originalValue}" ist keine gültige E-Mail Adresse.`
                )
        ),
    external_textfield_input: yup
        .string()
        .test(
            'is-full',
            'Bestätigen Sie die E-Mail-Adresse mit [ENTER].',
            (value, context) => !value
        ),
});
