import React, { ReactElement } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import CrmLabel from '../../helpers/CrmLabel';
import Email from '../../helpers/formatting/Email';
import Phone from '../../helpers/formatting/Phone';
import Url from '../../helpers/formatting/Url';
import Fax from '../../helpers/formatting/Fax';
import Mobile from '../../helpers/formatting/Mobile';
import {
    contactDetailOptions,
    ContactDetailType,
} from '../../../mappers/contactDetailMapper';

interface Props {
    type: ContactDetailType;
    value?: string;
    isEdited: boolean;
}

const ContactDetailItem = ({
    type,
    isEdited,
    value = '',
}: Props): ReactElement => {
    const { label } = contactDetailOptions[type];

    const render = (): ReactElement | string => {
        switch (type) {
            case ContactDetailType.email:
                return <Email email={value} />;
            case ContactDetailType.phone:
                return <Phone phone={value} />;
            case ContactDetailType.web:
                return <Url url={value} />;
            case ContactDetailType.fax:
                return <Fax fax={value} />;
            case ContactDetailType.mobile:
                return <Mobile mobile={value} />;

            default:
                return value;
        }
    };

    return (
        <>
            {isEdited ? (
                <Grid item xs={12}>
                    <Field
                        component={TextField}
                        name={type}
                        label={label}
                        type={type}
                    />
                </Grid>
            ) : (
                <>
                    <Grid item xs={3}>
                        <CrmLabel>{label}</CrmLabel>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="body2">
                            {value ? render() : ''}
                        </Typography>
                    </Grid>
                </>
            )}
        </>
    );
};

export default ContactDetailItem;
