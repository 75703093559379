import React, { useState } from 'react';

export interface GlobalContext {
    reloadPage: boolean;
    setReloadPage: { (status: boolean): void };
    backdropOpen: boolean;
    setBackdropOpen: { (status: boolean): void };
}
export const GlobalContext = React.createContext<GlobalContext>(
    {} as GlobalContext
);

interface Props {
    children: any;
}

const GlobalStore = ({ children }: Props) => {
    const [reloadPage, setReloadPage] = useState<boolean>(false);
    const [backdropOpen, setBackdropOpen] = useState<boolean>(false);

    return (
        <GlobalContext.Provider
            value={{ reloadPage, setReloadPage, backdropOpen, setBackdropOpen }}
        >
            {children}
        </GlobalContext.Provider>
    );
};

export default GlobalStore;
