export const dayOptions = Array.from(
    { length: 31 },
    (element: undefined, index: number) => ({
        label: (index + 1).toString().padStart(2, '0') + '.',
        value: index + 1,
    })
);

export const monthOptions = Array.from(
    { length: 12 },
    (element: undefined, index: number) => ({
        label: new Date(1999, index + 1, 0).toLocaleDateString('de', {
            month: 'long',
        }),
        value: index.toString(),
    })
);

export const fullYear = new Date().getFullYear();
export const yearOptions = Array.from({ length: 90 }, (item, index) => ({
    label: fullYear - index,
    value: fullYear - index,
}));
