export interface ContactType {
    id: number;
    contact_type: string;
}

const mapContactType = (input: any): ContactType => ({
    ...input,
});

export const mapContactTypes = (input: any): ContactType[] =>
    input.map(mapContactType);

export default mapContactType;
