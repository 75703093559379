import React, { ReactElement } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

const NotFound = (): ReactElement => {
    return (
        <Box mx="auto" maxWidth="500px">
            <Typography gutterBottom variant="h1">
                Nicht gefunden
            </Typography>
            <Typography gutterBottom>
                Leider konnten wir die von Ihnen aufgerufene Seite nicht finden.{' '}
                <br />
                Möglicherweise ist der Inhalt nicht mehr verfügbar oder der Link
                ist fehlerhaft.
            </Typography>
            <Button component={Link} to="/" color="primary">
                Zurück zum Dashboard
            </Button>
        </Box>
    );
};

export default NotFound;
