export interface Position {
    id: number;
    position_d: string | null;
    position_m: string;
    position_f: string | null;
}

const mapPosition = (input: any): Position => ({
    ...input,
});

export const mapPositions = (input: any): Position[] => input.map(mapPosition);

export default mapPosition;
