import React, {
    ChangeEvent,
    MouseEvent,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';
import {
    Backdrop,
    Box,
    CircularProgress,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    TextField,
} from '@material-ui/core';
import {
    Business,
    BusinessCenter,
    Close,
    Person,
    Search,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'react-use';
import Ajax from '../../helpers/Ajax';
import logError from '../../errors/logError';
import { mapContacts } from '../../mappers/contactMapper';
import { mapContactPersons } from '../../mappers/contactPersonMapper';
import HttpAuthenticationError from '../../errors/HttpAuthenticationError';

interface SearchResult {
    type: 'contact' | 'contact_person' | 'rival';
    label: string;
    additionalInformation?: string;
    id: string;
}

const minSearchLength = 3;

const SearchBox = (): ReactElement => {
    const classes = useStyles();
    const history = useHistory();
    const [showMobileSearch, setShowMobileSearch] = useState(false);
    const [showSearchResults, setShowSearchResults] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [results, setResults] = useState<SearchResult[] | null>(null);
    const textInputElement = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setShowSearchResults(!!searchQuery);
    }, [searchQuery]);

    const hasSearchQueryRequiredLength = searchQuery.length >= minSearchLength;

    const fetchResults = async () => {
        try {
            const contactResponse = await Ajax.get<Obj[]>('contacts/all', {
                search: searchQuery,
                withRelations: ['address'],
            });
            const contactPersonResponse = await Ajax.get<Obj[]>(
                'contact_persons/all',
                {
                    search: searchQuery,
                    withRelations: ['address'],
                }
            );
            const rivalResponse = await Ajax.get<Obj[]>('rivals', {
                search: searchQuery,
                withRelations: ['address'],
            });

            setResults([
                ...mapContactToSearchResult(contactResponse),
                ...mapContactPersonsToSearchResult(contactPersonResponse),
                ...mapRivalToSearchResult(rivalResponse),
            ]);
        } catch (error) {
            if (error instanceof HttpAuthenticationError) return;
            logError(error);
        }
    };

    useDebounce(
        async () => {
            if (!hasSearchQueryRequiredLength) {
                return;
            }
            await fetchResults();
        },
        300,
        [searchQuery]
    );

    const onClickResult = (event: MouseEvent<HTMLElement>) => {
        event.preventDefault();
        resetSearchResults();

        history.push(
            `/${event.currentTarget.dataset.type}/${event.currentTarget.dataset.id}`
        );
        return true;
    };

    const resetSearchResults = () => {
        setSearchQuery('');
        setResults([]);
        setShowSearchResults(false);
        setShowMobileSearch(false);
    };

    const onChangeSearchQuery = (event: ChangeEvent<HTMLInputElement>) => {
        setResults(null);
        setSearchQuery(event.target.value);
    };

    const openMobileSearch = () => {
        setShowMobileSearch(true);
        textInputElement.current?.focus();
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.mobileSearchWrapper}>
                <IconButton
                    onClick={openMobileSearch}
                    className={classes.mobileSearchIcon}
                >
                    <Search />
                </IconButton>
            </div>
            <Box
                className={[
                    classes.searchWrapper,
                    showMobileSearch ? 'active' : '',
                ].join(' ')}
            >
                <TextField
                    placeholder="Suche nach Kontakt, Ansprechpartner oder Wettbewerber..."
                    name="search"
                    type="text"
                    size="small"
                    margin="none"
                    value={searchQuery}
                    onChange={onChangeSearchQuery}
                    inputRef={textInputElement}
                    autoComplete="off"
                    InputProps={{
                        className: classes.searchBox,
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search color="disabled" />
                            </InputAdornment>
                        ),
                        endAdornment: (showMobileSearch || searchQuery) && (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={resetSearchResults}
                                    edge="end"
                                >
                                    <Close />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                {showSearchResults && (
                    <>
                        <Paper className={classes.results}>
                            <List dense>
                                {results === null &&
                                    hasSearchQueryRequiredLength && (
                                        <ListItem>
                                            <ListItemText
                                                primary={
                                                    <CircularProgress
                                                        size={20}
                                                        color="secondary"
                                                    />
                                                }
                                            />
                                        </ListItem>
                                    )}
                                {!hasSearchQueryRequiredLength && (
                                    <ListItem>
                                        <ListItemText
                                            primary={
                                                <>
                                                    Mindestens{' '}
                                                    <strong>
                                                        {minSearchLength}
                                                    </strong>{' '}
                                                    Zeichen eingeben...
                                                </>
                                            }
                                        />
                                    </ListItem>
                                )}
                                {results?.length === 0 && (
                                    <ListItem>
                                        <ListItemText
                                            primary={
                                                <>
                                                    Kein Treffer für{' '}
                                                    <strong>
                                                        {searchQuery}
                                                    </strong>
                                                </>
                                            }
                                        />
                                    </ListItem>
                                )}
                                {results?.map(result => (
                                    <ListItem
                                        button
                                        key={result.id}
                                        onClick={onClickResult}
                                        component="button"
                                        data-id={result.id}
                                        data-type={result.type}
                                    >
                                        <ListItemIcon>
                                            {result.type === 'contact' && (
                                                <Business />
                                            )}
                                            {result.type ===
                                                'contact_person' && <Person />}
                                            {result.type === 'rival' && (
                                                <BusinessCenter />
                                            )}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={result.label}
                                            secondary={
                                                result.additionalInformation
                                            }
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Paper>
                        <Backdrop
                            open
                            onClick={resetSearchResults}
                            className={classes.backdrop}
                        />
                    </>
                )}
            </Box>
        </div>
    );
};

export default SearchBox;

const useStyles = makeStyles(theme => {
    const mobileSearchBreakpoint = theme.breakpoints.down('xs');

    return {
        wrapper: {
            paddingLeft: theme.spacing(2),
        },
        mobileSearchWrapper: {
            display: 'none',
            textAlign: 'right',

            [mobileSearchBreakpoint]: {
                display: 'block',
            },
        },
        mobileSearchIcon: {
            color: theme.palette.primary.contrastText,
        },
        searchWrapper: {
            maxWidth: 500,
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
            position: 'relative',
            left: 0,
            right: 0,
            transition: theme.transitions.create('all'),
            background: theme.palette.primary.main,

            [mobileSearchBreakpoint]: {
                zIndex: theme.zIndex.appBar + 2,
                padding: theme.spacing(1),
                position: 'fixed',
                top: '-100%',
                maxWidth: 'none',

                '&.active': {
                    top: 0,
                },
            },
        },
        searchBox: {
            background: theme.palette.background.paper,
            zIndex: theme.zIndex.appBar + 2,
        },
        results: {
            position: 'absolute',
            top: theme.spacing(8),
            left: 0,
            right: 0,
            zIndex: theme.zIndex.appBar + 2,
            maxHeight: '75vh',
            overflowX: 'scroll',

            [mobileSearchBreakpoint]: {
                margin: theme.spacing(1),
                top: theme.spacing(7),
            },
        },
        backdrop: {
            zIndex: theme.zIndex.appBar + 1,
        },
    };
});

const mapContactToSearchResult = (response: Obj[]): SearchResult[] =>
    mapContacts(response).map(contact => ({
        type: 'contact',
        label: contact.name,
        id: contact.id,
        additionalInformation: contact.address?.city,
    }));

const mapContactPersonsToSearchResult = (response: Obj[]): SearchResult[] =>
    mapContactPersons(response).map(contactPerson => ({
        type: 'contact_person',
        label: contactPerson.nameSortable,
        id: contactPerson.id,
        additionalInformation: contactPerson.main_contact?.name,
    }));

const mapRivalToSearchResult = (response: Obj[]): SearchResult[] =>
    mapContacts(response).map(rival => ({
        type: 'rival',
        label: rival.name,
        id: rival.id,
        additionalInformation: rival.address?.city,
    }));
