import React, { ReactElement, useState } from 'react';
import { Checkbox } from '@material-ui/core';

import Ajax from '../../../helpers/Ajax';
import logError from '../../../errors/logError';
import { useSnackbar } from 'notistack';

interface Props {
    officeUnique: string;
    restrictedStatus: boolean;
}

const OfficeCheckbox = ({
    officeUnique,
    restrictedStatus,
}: Props): ReactElement => {
    const { enqueueSnackbar } = useSnackbar();
    const [restricted, setRestricted] = useState(restrictedStatus);

    const changeRestriction = async () => {
        try {
            await Ajax.post<never>(
                'offices/' + officeUnique + '/crm_restricted',
                {}
            );
            setRestricted(!restricted);
            enqueueSnackbar('Restricted Status wurde geändert', {
                variant: 'success',
            });
        } catch (error) {
            enqueueSnackbar('Restricted Status konnte nicht geändert werden', {
                variant: 'error',
            });
            logError(error);
        }
    };

    return <Checkbox checked={restricted} onChange={changeRestriction} />;
};

export default OfficeCheckbox;
