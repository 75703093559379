import React from 'react';
import { IconButton, Typography } from '@material-ui/core';
import { Close, Edit, Save } from '@material-ui/icons';
import { User } from '../../../mappers/userMapper';
import useEditable from '../../../hooks/useEditable';
import FormBuilder from '../../helpers/forms/FormBuilder';
import { OfficeEntity, UserEntity } from '../../../helpers/entities';
import CrmCard from '../../helpers/CrmCard';
import CrmLabel from '../../helpers/CrmLabel';
import EntityAutocompleteMultiple from '../../helpers/forms/EntityAutocompleteMultiple';

interface Props {
    user: User;
    reloadHandler(): void;
}

const Offices = ({ user, reloadHandler }: Props) => {
    const { isEdited, editOpen, editClose } = useEditable();

    const editResponseHandler = () => {
        editClose();
        reloadHandler();
    };

    if (isEdited) {
        return (
            <FormBuilder
                action={UserEntity.apiBaseUrl + '/' + user.id}
                initialValues={{}}
                responseHandler={editResponseHandler}
                errorMessage="Fehler beim Speichern der Niederlassungen!"
            >
                <CrmCard
                    title="Niederlassungen"
                    titleAction={
                        <>
                            <IconButton size="small" type="submit">
                                <Save />
                            </IconButton>
                            <IconButton size="small" onClick={editClose}>
                                <Close />
                            </IconButton>
                        </>
                    }
                >
                    <EntityAutocompleteMultiple
                        entity={OfficeEntity}
                        minInputLength={0}
                        selectedElements={user.offices}
                    />
                </CrmCard>
            </FormBuilder>
        );
    }

    return (
        <CrmCard
            title="Niederlassungen"
            titleAction={
                <IconButton size="small" onClick={editOpen}>
                    <Edit />
                </IconButton>
            }
        >
            <CrmLabel>
                Niederlassung
                {user.offices && user.offices.length > 1 && 'en'}
            </CrmLabel>
            <Typography variant="body2">
                {user.offices && user.offices.length
                    ? user.offices
                          .map(office => office.name)
                          .sort()
                          .join(', ')
                    : 'Bisher wurde keine Niederlassung zugewiesen.'}
            </Typography>
        </CrmCard>
    );
};

export default Offices;
