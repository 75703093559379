import React, { ReactElement, useMemo } from 'react';
import { IconButton, List, Typography } from '@material-ui/core';
import {
    Add,
    AddAPhoto,
    Close,
    Edit,
    Link as LinkIcon,
} from '@material-ui/icons';
import CrmCard from '../../helpers/CrmCard';
import { Contact } from '../../../mappers/contactMapper';
import useEditable from '../../../hooks/useEditable';
import useDialog from '../../../hooks/useDialog';
import CrmDialog from '../../helpers/CrmDialog';
import ContactPersonAddDialog from './ContactPersonAddDialog';
import Ajax from '../../../helpers/Ajax';
import { useSnackbar } from 'notistack';
import ContactPersonLinkDialog from './ContactPersonLinkDialog';
import ContactPersonItem from './ContactPersonItem';
import logError from '../../../errors/logError';
import { ContactEntity } from '../../../helpers/entities';
import usePagination from '../../../hooks/usePagination';
import { Pagination } from '@material-ui/lab';
import { ContactPerson } from '../../../mappers/contactPersonMapper';
import BusinessCardScanDialog from '../../BusinessCardScanDialog';

interface Props {
    contact: Contact;
    reloadContact(): void;
}

const ContactPersons = ({ contact, reloadContact }: Props): ReactElement => {
    const { isEdited, editOpen, editClose } = useEditable();
    const { enqueueSnackbar } = useSnackbar();
    const {
        openDialog: openContactPersonAddDialog,
        closeDialog: closeContactPersonAddDialog,
        dialogOpen: contactPersonAddDialogOpen,
    } = useDialog();
    const {
        openDialog: openContactPersonLinkDialog,
        closeDialog: closeContactPersonLinkDialog,
        dialogOpen: contactPersonLinkDialogOpen,
    } = useDialog();
    const {
        dialogOpen: businessCardScanDialogOpen,
        closeDialog: closeBusinessCardScanDialog,
        openDialog: openBusinessCardScanDialog,
    } = useDialog();

    const {
        page,
        totalPages,
        startEntryReset,
        handlePageChange,
        startEntryIndex,
        endEntryIndex,
    } = usePagination(contact.contact_persons?.length ?? 0, 3);

    const slicedContactPersons: ContactPerson[] = useMemo(
        () =>
            contact.contact_persons?.length
                ? contact.contact_persons.slice(startEntryIndex, endEntryIndex)
                : contact.contact_persons!,
        [startEntryIndex, contact]
    );

    const contactPersonAddResponseHandler = () => {
        reloadContact();
        closeContactPersonAddDialog();
    };

    const businessCardScanDialogResponseHandler = (): void => {
        reloadContact();
        closeBusinessCardScanDialog();
    };

    const contactPersonLinkResponseHandler = () => {
        reloadContact();
        closeContactPersonLinkDialog();
    };

    const handleContactPersonUnlink = async (contactPersonId: string) => {
        try {
            await Ajax.delete<any>(
                ContactEntity.apiBaseUrl +
                    '/' +
                    contact.id +
                    '/contact_persons/' +
                    contactPersonId
            );
            reloadContact();
            startEntryReset();
        } catch (e) {
            enqueueSnackbar('Fehler beim Lösen des Ansprechpartners!', {
                variant: 'error',
            });
            logError(e);
        }
    };

    return (
        <CrmCard
            footer={
                totalPages > 1 && (
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        size="small"
                    />
                )
            }
            title="Ansprechpartner"
            disablePaddingTop={!!contact.contact_persons?.length}
            titleAction={
                isEdited ? (
                    <IconButton size="small" onClick={editClose}>
                        <Close />
                    </IconButton>
                ) : (
                    <>
                        <IconButton
                            size="small"
                            onClick={openBusinessCardScanDialog}
                        >
                            <AddAPhoto />
                        </IconButton>
                        <IconButton
                            size="small"
                            onClick={openContactPersonAddDialog}
                        >
                            <Add />
                        </IconButton>
                        <IconButton
                            size="small"
                            onClick={openContactPersonLinkDialog}
                        >
                            <LinkIcon />
                        </IconButton>
                        <IconButton size="small" onClick={editOpen}>
                            <Edit />
                        </IconButton>
                    </>
                )
            }
        >
            {slicedContactPersons.length > 0 ? (
                <List dense>
                    {slicedContactPersons.map(contactPerson => (
                        <ContactPersonItem
                            key={contactPerson.id}
                            contactPerson={contactPerson}
                            isEdited={isEdited}
                            handleContactPersonUnlink={
                                handleContactPersonUnlink
                            }
                        />
                    ))}
                </List>
            ) : (
                <Typography variant="body2">
                    Bisher wurden keine Ansprechpartner zugewiesen.
                </Typography>
            )}
            <CrmDialog
                dialogOpen={businessCardScanDialogOpen}
                closeDialog={closeBusinessCardScanDialog}
                confirmClose
            >
                <BusinessCardScanDialog
                    closeDialog={closeBusinessCardScanDialog}
                    contactId={contact.id}
                    responseHandler={businessCardScanDialogResponseHandler}
                />
            </CrmDialog>
            <CrmDialog
                dialogOpen={contactPersonAddDialogOpen}
                closeDialog={closeContactPersonAddDialog}
                confirmClose
            >
                <ContactPersonAddDialog
                    closeDialog={closeContactPersonAddDialog}
                    contactId={contact.id}
                    responseHandler={contactPersonAddResponseHandler}
                />
            </CrmDialog>
            <CrmDialog
                dialogOpen={contactPersonLinkDialogOpen}
                closeDialog={closeContactPersonLinkDialog}
                maxWidth="sm"
            >
                <ContactPersonLinkDialog
                    closeDialog={closeContactPersonLinkDialog}
                    contactId={contact.id}
                    responseHandler={contactPersonLinkResponseHandler}
                />
            </CrmDialog>
        </CrmCard>
    );
};

export default ContactPersons;
