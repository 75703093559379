import { Address } from '../mappers/addressMapper';

interface ApiCoordinates {
    lat: number;
    lon: number;
}

interface Coordinates {
    latitude: number | null;
    longitude: number | null;
}

export const fetchCoordinates = async (
    values: Address
): Promise<Coordinates> => {
    const options: RequestInit = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
    };

    if (!values.address || !values.postcode || !values.city) {
        console.warn('Es wurde keine vollständige Adresse angegeben.');
        return {
            latitude: null,
            longitude: null,
        };
    }

    const urlSearchParams = new URLSearchParams({
        format: 'json',
        q: `${values.address}, ${values.postcode} ${values.city}`,
    });

    const responseOpenStreetMap = await fetch(
        `//nominatim.openstreetmap.org/search?${urlSearchParams}`,
        options
    );

    if (!responseOpenStreetMap.ok) {
        console.warn(
            'API Aufruf zum Abruf der Koordinaten der Adresse fehlgeschlagen.'
        );
        return {
            latitude: null,
            longitude: null,
        };
    }

    const coordinates: ApiCoordinates[] = await responseOpenStreetMap.json();

    if (
        !coordinates ||
        coordinates.length <= 0 ||
        !coordinates[0].lat ||
        !coordinates[0].lon
    ) {
        console.warn('API lieferte keine Koordinaten zurück.');
        return {
            latitude: null,
            longitude: null,
        };
    }

    return {
        latitude: coordinates[0].lat,
        longitude: coordinates[0].lon,
    };
};
