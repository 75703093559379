import { AboptimalUser } from '../mappers/aboptimalUserMapper';

export enum filterValues {
    noContact = 'noContact',
    withContact = 'withContact',
    all = 'all',
}

export const filterAboptimalUsers = (
    filter: filterValues,
    aboptimalUser: AboptimalUser
): boolean => {
    if (aboptimalUser.hidden) {
        return false;
    }
    if (filter === filterValues.all) {
        return true;
    }
    if (filter === filterValues.withContact) {
        return !!aboptimalUser.contact;
    }
    return !aboptimalUser.contact;
};
