export interface Address {
    id?: number;
    address: string;
    postcode: string;
    city: string;
    longitude: number | null;
    latitude: number | null;
}

const mapAddress = (input: any): Address => ({
    ...input,
    address: input.address ?? '',
    postcode: input.postcode ?? '',
    city: input.city ?? '',
    longitude: input.longitude ?? null,
    latitude: input.latitude ?? null,
});

export default mapAddress;
