import React, { ReactElement } from 'react';
import { Call } from '@material-ui/icons';
import FormattingBase from './FormattingBase';

interface Props {
    phone: string;
}

const Phone = ({ phone }: Props): ReactElement => {
    return (
        <FormattingBase
            label={phone}
            href={`tel:${phone.replace(/\D+/g, '')}`}
            title={`${phone} anrufen`}
            icon={Call}
        />
    );
};

export default Phone;
