import React, { ReactElement } from 'react';
import { IconButton, Typography } from '@material-ui/core';
import { LinkOff, Close, Edit, Save } from '@material-ui/icons';
import useEditable from '../../../hooks/useEditable';
import FormBuilder from '../../helpers/forms/FormBuilder';
import CrmCard from '../../helpers/CrmCard';
import CrmLabel from '../../helpers/CrmLabel';
import { IEmpfehler } from '../../../../../../../../resources/assets/react/types';
import { EmpfehlerEntity } from '../../../helpers/entities';
import EntityAutocomplete from '../../helpers/forms/EntityAutocomplete';
import Ajax from '../../../helpers/Ajax';
import logError from '../../../errors/logError';
import { useSnackbar } from 'notistack';

interface Props {
    empfehler: IEmpfehler | null | undefined;
    apiUrl: string;
    reloadHandler(): void;
}

const Empfehler = ({
    empfehler,
    apiUrl,
    reloadHandler,
}: Props): ReactElement => {
    const { isEdited, editOpen, editClose } = useEditable();
    const { enqueueSnackbar } = useSnackbar();
    const deleteEmpfehler = async () => {
        try {
            await Ajax.post<any>(apiUrl, {
                empfehler_unique: null,
            });
            editClose();
            reloadHandler();
        } catch (e) {
            enqueueSnackbar('Fehler beim Löschen des Empfehlers!', {
                variant: 'error',
            });
            logError(e);
        }
    };
    const editResponseHandler = () => {
        editClose();
        reloadHandler();
    };
    if (isEdited) {
        return (
            <FormBuilder
                action={apiUrl}
                initialValues={{
                    empfehler_unique: empfehler ? empfehler.unique : null,
                }}
                responseHandler={editResponseHandler}
                errorMessage="Fehler beim Speichern des Empfehlers!"
            >
                <CrmCard
                    title="Empfehler"
                    titleAction={
                        <>
                            <IconButton
                                disabled={!empfehler}
                                size="small"
                                onClick={deleteEmpfehler}
                            >
                                <LinkOff />
                            </IconButton>
                            <IconButton size="small" type="submit">
                                <Save />
                            </IconButton>
                            <IconButton size="small" onClick={editClose}>
                                <Close />
                            </IconButton>
                        </>
                    }
                >
                    <EntityAutocomplete
                        entity={EmpfehlerEntity}
                        name="empfehler_unique"
                        preloadAllEntries={false}
                        autoFocus
                    />
                </CrmCard>
            </FormBuilder>
        );
    }

    return (
        <CrmCard
            title="Empfehler"
            titleAction={
                <IconButton size="small" onClick={editOpen}>
                    <Edit />
                </IconButton>
            }
        >
            {empfehler ? (
                <>
                    <CrmLabel>Empfehler</CrmLabel>
                    <Typography variant="body2">{empfehler.name}</Typography>
                </>
            ) : (
                'Bisher wurde kein Empfehler zugewiesen.'
            )}
        </CrmCard>
    );
};

export default Empfehler;
