import React, { useContext } from 'react';
import { GlobalContext } from '../../stores/GlobalStore';
import { Backdrop, CircularProgress, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CrmTheme } from '../../theme';

const CrmBackdrop = () => {
    const { backdropOpen } = useContext(GlobalContext);
    const classes = useStyles();

    return (
        <Backdrop open={backdropOpen} className={classes.backdrop}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

const useStyles = makeStyles((theme: CrmTheme) =>
    createStyles({
        backdrop: {
            zIndex: 999,
        },
    })
);

export default CrmBackdrop;
