import React, { ReactElement, useContext } from 'react';
import { AppState, AuthContext } from '../stores/AuthStore';
import LoginForm from './modules/LoginForm';
import CrmAppBar from './modules/CrmAppBar';
import DecliningCaseNumbers from './modules/DecliningCaseNumbers';
import { Box, Container } from '@material-ui/core';
import { Route, Switch } from 'react-router-dom';
import Dashboard from './Dashboard';
import Contacts from './Contacts';
import ContactView from './ContactView';
import ContactPersonView from './ContactPersonView';
import ContactPersons from './ContactPersons';
import Users from './Users';
import UserView from './UserView';
import Offices from './Offices';
import Reports from './Reports';
import RouteRestricted from './helpers/RouteRestricted';
import CrmBackdrop from './helpers/CrmBackdrop';
import Statistics from './Statistics';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandling from './ErrorHandling';
import logError from '../errors/logError';
import NotFound from './NotFound';
import Me from './Me';
import DataSetOverView, { baseUrl as baseDataUrl } from './DataSetOverView';
import Rivals from './Rivals';
import RivalView from './RivalView';
import ContactsMap from './ContactsMap';
import AboptimalUsers from './AboptimalUsers';
import RentalCarUsage from './RentalCarUsage';

const Main = (): ReactElement => {
    const { state } = useContext(AuthContext);

    const onResetApp = () => {
        window.location.reload();
    };

    return (
        <ErrorBoundary
            FallbackComponent={ErrorHandling}
            onReset={onResetApp}
            onError={logError}
        >
            {state === AppState.loading && <span>Loading ...</span>}
            {state === AppState.unauthenticated && <LoginForm />}
            {state === AppState.authenticated && (
                <>
                    <CrmBackdrop />
                    <CrmAppBar />
                    <Container component="main">
                        <Box my={5}>
                            <Switch>
                                <Route exact path="/" component={Dashboard} />

                                <Route exact path="/me" component={Me} />

                                <Route
                                    exact
                                    path="/contacts"
                                    component={Contacts}
                                />
                                <Route
                                    exact
                                    path="/contact/:contactId"
                                    component={ContactView}
                                />
                                <Route
                                    exact
                                    path="/contactsMap"
                                    component={ContactsMap}
                                />
                                <Route
                                    exact
                                    path="/contactsMap/:contactId"
                                    component={ContactsMap}
                                />

                                <Route
                                    exact
                                    path="/rivals"
                                    component={Rivals}
                                />
                                <Route
                                    exact
                                    path="/rival/:rivalId"
                                    component={RivalView}
                                />

                                <Route
                                    exact
                                    path="/contact_persons"
                                    component={ContactPersons}
                                />
                                <Route
                                    exact
                                    path="/contact_person/:contactPersonId"
                                    component={ContactPersonView}
                                />

                                <Route
                                    exact
                                    path="/statistics"
                                    component={Statistics}
                                />

                                <RouteRestricted
                                    path="/users"
                                    component={Users}
                                    permission="user_administration"
                                />
                                <RouteRestricted
                                    path="/user/:userId"
                                    component={UserView}
                                    permission="user_administration"
                                />

                                <RouteRestricted
                                    path="/offices"
                                    component={Offices}
                                    permission="office_administration"
                                />

                                <Route
                                    exact
                                    path="/reports"
                                    component={Reports}
                                />

                                <Route
                                    exact
                                    path="/declining_cases/declining_case_numbers"
                                    component={DecliningCaseNumbers}
                                />

                                <Route
                                    exact
                                    path={`${baseDataUrl}:slug?/:id?`}
                                    component={DataSetOverView}
                                />

                                <Route
                                    exact
                                    path="/settings/aboptimalUsers"
                                    component={AboptimalUsers}
                                />

                                <Route
                                    exact
                                    path="/rentalCarUsage"
                                    component={RentalCarUsage}
                                />

                                <Route component={NotFound} />
                            </Switch>
                        </Box>
                    </Container>
                </>
            )}
        </ErrorBoundary>
    );
};

export default Main;
