import React, { ReactElement, useState } from 'react';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import DateFnsUtils from '@date-io/date-fns';
import { de } from 'date-fns/locale';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

interface Props {
    date: Date | null;
    label: string;
    id: string;
    noNull: boolean;
    changeDateValue: (value: Date | null, fieldName: string) => void;
    required?: boolean;
    maxDate?: Date | null;
}

const DateField = ({
    date,
    label,
    id,
    noNull,
    changeDateValue,
    required = false,
    maxDate,
}: Props): ReactElement => {
    const [valueDate, setValueDate] = useState(date);

    const handleDateChange = (date: MaterialUiPickersDate | null) => {
        setValueDate(date);
        changeDateValue(date, id);
    };

    const handleClearDate = (event: React.MouseEvent) => {
        event.stopPropagation();
        handleDateChange(null);
        changeDateValue(null, id);
    };

    return (
        <MuiPickersUtilsProvider locale={de} utils={DateFnsUtils}>
            <DatePicker
                format="dd.MM.yyyy"
                required={required}
                maxDate={maxDate}
                margin="normal"
                cancelLabel="Abbrechen"
                id={id}
                label={label}
                value={valueDate}
                onChange={handleDateChange}
                InputProps={{
                    endAdornment: !noNull ? (
                        <IconButton onClick={handleClearDate}>
                            <ClearIcon />
                        </IconButton>
                    ) : null,
                }}
            />
        </MuiPickersUtilsProvider>
    );
};

export default DateField;
