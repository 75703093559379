import HttpBaseError from './HttpBaseError';

export default class HttpValidationError<T> extends HttpBaseError {
    public readonly errors: T;

    constructor(traceId: string, errors: T) {
        super(traceId, 'Invalid data');
        if (Error?.captureStackTrace) {
            Error.captureStackTrace(this, HttpValidationError);
        }
        this.name = 'HttpValidationError';

        this.errors = errors;
        Object.setPrototypeOf(this, HttpValidationError.prototype);
    }
}
