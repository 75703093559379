import React, { useEffect, useState } from 'react';

const usePagination = (
    totalEntries: number,
    perPage = 3
): {
    startEntryIndex: number;
    totalPages: number;
    page: number;
    endEntryIndex: number;
    startEntryReset: () => void;
    handlePageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
} => {
    const [page, setPage] = useState(1);
    const [startEntryIndex, setStartEntryIndex] = useState(0);

    const totalPages = Math.ceil(totalEntries / perPage);

    const startEntryReset = (): void => {
        setStartEntryIndex(0);
        setPage(1);
    };

    const handlePageChange = (
        event: React.ChangeEvent<unknown>,
        page: number
    ): void => {
        setPage(page);
    };

    useEffect(() => {
        setStartEntryIndex((page - 1) * perPage);
    }, [page]);

    return {
        page,
        totalPages,
        startEntryReset,
        handlePageChange,
        startEntryIndex,
        endEntryIndex: startEntryIndex + perPage,
    };
};

export default usePagination;
