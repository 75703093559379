import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { CrmTheme } from '../../../theme';
import {
    Box,
    fade,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    useTheme,
} from '@material-ui/core';
import { Line } from 'react-chartjs-2';
import CrmCard from '../../helpers/CrmCard';
import Ajax from '../../../helpers/Ajax';
import dayjs from 'dayjs';
import { Report } from '../../../mappers/reportMapper';
import { Contact } from '../../../mappers/contactMapper';
import { getContactRecordRatio } from '../../../helpers/getContactRecordRatio';
import {
    getZeroFilledRecords,
    MonthlyRecords,
} from '../../../helpers/statistics';
import { RentalCarReport } from '../../../mappers/rentalCarReportMapper';
import { Settings } from '@material-ui/icons';
import HttpValidationError from '../../../errors/HttpValidationError';
import logError from '../../../errors/logError';

interface Props {
    contact: Contact;
    reports?: Report[];
}

const Statistic = ({ contact, reports }: Props): ReactElement | null => {
    const [monthlyRecords, setMonthlyRecords] = useState<MonthlyRecords | null>(
        null
    );
    const [monthlyRecordsSliced, setMonthlyRecordsSliced] =
        useState<MonthlyRecords | null>(null);
    const [ratio, setRatio] = useState<string | null>(null);
    const theme: CrmTheme = useTheme();
    const [months, setMonths] = useState<number>(0);
    const [showSettings, setShowSettings] = useState<boolean | null>(null);

    useEffect(() => {
        getMonthlyRecords();
    }, [contact.empfehler?.unique, reports]);

    useEffect(() => {
        if (monthlyRecords === null) {
            setMonthlyRecordsSliced(null);
            return;
        }

        const sliced =
            months === 0
                ? monthlyRecords
                : Object.keys(monthlyRecords)
                      .slice(months * -1)
                      .reduce((result, key) => {
                          result[key] = monthlyRecords[key];

                          return result;
                      }, {} as MonthlyRecords);

        setMonthlyRecordsSliced(sliced);
        setRatio(getContactRecordRatio(sliced));
    }, [months, monthlyRecords]);

    const onClickSettings = () => setShowSettings(!showSettings);

    const getMonthlyRecords = async (): Promise<void> => {
        try {
            const records = await Ajax.get<MonthlyRecords>(
                'contacts/' + contact.id + '/records/monthly'
            );

            setMonthlyRecords(getZeroFilledRecords(records, reports ?? []));
        } catch (error) {
            if (error instanceof HttpValidationError) return;
            logError(error);
        }
    };

    if (monthlyRecordsSliced === null) {
        return null;
    }

    const reportsDataset: Array<number> = reports
        ? getReportsDataset(reports, monthlyRecordsSliced)
        : Array(Object.keys(monthlyRecordsSliced).length).fill(0);

    const rentalCarReportsDataset = contact.rental_car_reports
        ? getRentalCarReportsDataset(
              contact.rental_car_reports,
              monthlyRecordsSliced
          )
        : Array(Object.keys(monthlyRecordsSliced).length).fill(0);

    const statisticsData = {
        labels: Object.keys(monthlyRecordsSliced).map(date =>
            dayjs(date).format("MMM 'YY")
        ),
        datasets: [
            {
                label: 'Kasko Fälle',
                yAxisID: 'records',
                borderColor: theme.palette.primary.main,
                backgroundColor: fade(theme.palette.primary.main, 0.1),
                pointBackgroundColor: theme.palette.primary.main,
                data: Object.values(monthlyRecordsSliced).map(
                    date => date.kasko
                ),
            },
            {
                label: 'Haftpflicht Fälle',
                yAxisID: 'records',
                borderColor: theme.palette.secondary.main,
                backgroundColor: fade(theme.palette.secondary.main, 0.1),
                pointBackgroundColor: theme.palette.secondary.main,
                data: Object.values(monthlyRecordsSliced).map(
                    date => date.haftpflicht
                ),
            },
            {
                label: 'Kontaktaufnahmen',
                yAxisID: 'reports',
                data: reportsDataset,
                type: 'bar',
            },
            {
                label: 'Nutzung des Mietwagen-Tools',
                yAxisID: 'records',
                borderColor: theme.palette.yellow.main,
                backgroundColor: fade(theme.palette.yellow.main, 0.1),
                pointBackgroundColor: theme.palette.yellow.main,
                data: rentalCarReportsDataset,
            },
        ],
    };
    const maxRecords = Math.max(
        ...Object.values(monthlyRecordsSliced).map(date => date.kasko),
        ...Object.values(monthlyRecordsSliced).map(date => date.haftpflicht),
        ...rentalCarReportsDataset,
        10
    );

    const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
        setMonths(event.target.value as number);
    };

    return (
        <CrmCard
            title="Statistik"
            titleAction={
                <>
                    <IconButton size="small" onClick={onClickSettings}>
                        <Settings />
                    </IconButton>
                </>
            }
            footer={ratio}
        >
            {showSettings !== null && (
                <Box mb={2} display={showSettings ? 'block' : 'none'}>
                    <FormControl size="small" margin="normal">
                        <InputLabel id="select-label">Zeitraum</InputLabel>
                        <Select
                            id="monthsQuantity"
                            value={months}
                            labelId="select-label"
                            label="Zeitraum"
                            onChange={handleChange}
                        >
                            <MenuItem value={3}>3 Monate</MenuItem>
                            <MenuItem value={6}>6 Monate</MenuItem>
                            <MenuItem value={12}>1 Jahr</MenuItem>
                            <MenuItem value={2 * 12}>2 Jahre</MenuItem>
                            <MenuItem value={5 * 12}>5 Jahre</MenuItem>
                            <MenuItem value={0}>Alles</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            )}
            {monthlyRecordsSliced && (
                <Line
                    type="line"
                    data={statisticsData}
                    options={{
                        scales: {
                            records: {
                                type: 'linear',
                                axis: 'y',
                                min: 0,
                                ticks: {
                                    stepSize: maxRecords >= 20 ? 10 : 2,
                                    max: Math.ceil(maxRecords / 10) * 10,
                                },
                            },
                            reports: {
                                type: 'linear',
                                axis: 'y',
                                min: 0,
                                position: 'right',
                                display: false,
                                ticks: {
                                    stepSize: 1,
                                    max: Math.max(...reportsDataset, 1),
                                },
                            },
                            x: {
                                type: 'category',
                                ticks: {
                                    minRotation: 45,
                                },
                            },
                        },
                    }}
                />
            )}
        </CrmCard>
    );
};

export default Statistic;

const getReportsDataset = (reports: Report[], records: Obj): Array<number> => {
    return Object.keys(records).map(
        date =>
            reports.filter(
                report =>
                    dayjs(report.date).format('YYYY-MM') ===
                    dayjs(date).format('YYYY-MM')
            ).length
    );
};

const getRentalCarReportsDataset = (
    rentalCarReports: RentalCarReport[],
    records: MonthlyRecords
): Array<number> => {
    return Object.keys(records).map(
        date =>
            rentalCarReports.find(
                rentalCarReport =>
                    dayjs(rentalCarReport.reported_at).format('YYYY-MM') ===
                    dayjs(date).format('YYYY-MM')
            )?.calculations ?? 0
    );
};
