import React, { ReactElement } from 'react';

interface Props {
    children: string;
}

const Nl2br = ({ children: text }: Props): ReactElement => {
    const lines = text.split('\n');
    const lastLine = lines.length - 1;

    return (
        <>
            {lines.map((line, index) => (
                <React.Fragment key={index}>
                    {line}
                    {index !== lastLine && <br />}
                </React.Fragment>
            ))}
        </>
    );
};

export default Nl2br;
