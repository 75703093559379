import React, { memo } from 'react';
import { createStyles, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CrmTheme } from '../../theme';

interface Props {
    children: any;
}

const CrmLabel = ({ children }: Props) => {
    const classes = useStyles();

    return (
        <Typography
            className={classes.label}
            component="h4"
            variant="subtitle2"
            color="textSecondary"
        >
            {children}
        </Typography>
    );
};

export default memo(CrmLabel);

const useStyles = makeStyles((theme: CrmTheme) =>
    createStyles({
        label: {
            '&:not(:first-child)': {
                marginTop: theme.spacing(2),
            },
        },
    })
);
