export interface WorkshopSystem {
    id: number;
    workshop_system: string;
}

const mapWorkshopSystem = (input: any): WorkshopSystem => ({
    ...input,
});

export const mapWorkshopSystems = (input: any): WorkshopSystem[] =>
    input.map(mapWorkshopSystem);

export default mapWorkshopSystem;
