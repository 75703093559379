import dayjs, { Dayjs } from 'dayjs';

export const daysUntilDate = (date: Dayjs, now: Dayjs = dayjs()): number => {
    const dateMidnight = date.hour(0).minute(0).second(0).millisecond(0);
    const nowMidnight = now.hour(0).minute(0).second(0).millisecond(0);
    const targetDate = dateMidnight.isBefore(nowMidnight)
        ? dateMidnight.add(1, 'year')
        : dateMidnight;

    return targetDate.diff(nowMidnight, 'day');
};
