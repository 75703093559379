import React, { ReactElement, useEffect, useState } from 'react';
import {
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from '@material-ui/core';
import { User } from '../../../mappers/userMapper';
import logError from '../../../errors/logError';
import Ajax from '../../../helpers/Ajax';
import UserCells from '../Users/UserCells';

interface Props {
    officeUnique: string | null;
}

const OfficeUsersTable = ({ officeUnique }: Props): ReactElement => {
    const [users, setUsers] = useState<Array<User>>([]);
    const [page, setPage] = React.useState(0);
    const [isLoading, setLoading] = useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    useEffect(() => {
        officeUnique && fetchResults();
        setLoading(true);
    }, []);

    const fetchResults = async (): Promise<void> => {
        try {
            const response = await Ajax.get<Array<User>>(
                'offices/' + officeUnique + '/crm_users'
            );
            setUsers(response);
            setLoading(false);
        } catch (error) {
            logError(error);
        }
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        newPage: number
    ) => {
        setPage(newPage);
    };

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell> Nachname </TableCell>
                        <TableCell> Vorname </TableCell>
                        <TableCell> E-Mail </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!isLoading &&
                        (users.length !== 0 ? (
                            users
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((user: User) => (
                                    <UserCells user={user} key={user.id} />
                                ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={2}>
                                    Momentan sind dem Standort keine Benutzer
                                    zugewiesen
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
            {isLoading && <LinearProgress />}
            {!isLoading && users.length > 5 && (
                <TablePagination
                    rowsPerPageOptions={[5, 10]}
                    component="div"
                    count={users.length}
                    labelRowsPerPage="Einträge pro Seite:"
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            )}
        </TableContainer>
    );
};

export default OfficeUsersTable;
