import CallGenderizeIoHttpError from './CallGenderizeIoHttpError';

export default class CallGenderizeIoHttpValidationError extends CallGenderizeIoHttpError {
    constructor(message: string, error: string) {
        super(message + ': ', error);
        if (Error?.captureStackTrace) {
            Error.captureStackTrace(this, CallGenderizeIoHttpValidationError);
        }
        this.name = 'CallGenderizeIoHttpValidationError';
        Object.setPrototypeOf(
            this,
            CallGenderizeIoHttpValidationError.prototype
        );
    }
}
