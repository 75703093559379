import dayjs from 'dayjs';
import { MonthlyRecords } from './statistics';

export interface DateRecords {
    [date: string]: number;
}

export interface MonthlyRecordsByYears {
    [year: string]: {
        kasko: DateRecords;
        haftpflicht: DateRecords;
    };
}

export interface MonthlyRentalCarReportsByYears {
    currentYear: DateRecords;
    lastYear: DateRecords;
}

export const createMonthlyRecordsByYears = (
    records: MonthlyRecords
): MonthlyRecordsByYears => {
    const monthlyRecordsTmp: MonthlyRecordsByYears = {};

    Object.keys(records).forEach(date => {
        const year = dayjs(date).format('YYYY');
        if (!(year in monthlyRecordsTmp)) {
            monthlyRecordsTmp[year] = {
                kasko: {},
                haftpflicht: {},
            };
        }
        monthlyRecordsTmp[year]['haftpflicht'][date] =
            records[date].haftpflicht;
        monthlyRecordsTmp[year]['kasko'][date] = records[date].kasko;
    });
    return monthlyRecordsTmp;
};

export const createMonthlyRentalCarReportsByYears = (
    reports: DateRecords,
    currentYear: string
): MonthlyRentalCarReportsByYears =>
    Object.keys(reports).reduce(
        (carry, date) => {
            if (dayjs(date).format('YYYY') === currentYear) {
                carry.currentYear[date] = reports[date];
                return carry;
            }
            carry.lastYear[date] = reports[date];
            return carry;
        },
        {
            lastYear: {},
            currentYear: {},
        } as MonthlyRentalCarReportsByYears
    );
