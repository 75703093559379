export interface File {
    id: string;
    label: string;
    originalFileName: string;
    uri: string;
}

export const mapFile = (input: any): File => ({
    id: input.id,
    label: input.label ?? '',
    originalFileName: input.original_file_name ?? '',
    uri: input.uri,
});

export const mapFiles = (inputs: any[]): File[] =>
    inputs.map(input => mapFile(input));
