import React, {
    ChangeEvent,
    ReactElement,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    Box,
    IconButton,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Tooltip,
    Typography,
} from '@material-ui/core';
import Ajax from '../../helpers/Ajax';
import logError from '../../errors/logError';
import { AuthContext } from '../../stores/AuthStore';
import { DecliningCases } from '../../mappers/DecliningCaseNumbersMapper';
import {
    getLastMonthNameAsString,
    getMonthBeforeSevenMonthsNameAsString,
    getPenultimateMonthNameAsString,
} from '../../helpers/getLastMonthNameAsString';
import DecliningCaseNumbersRows from './DecliningCaseNumbers/DecliningCaseNumbersRows';
import { FilterList, GetApp } from '@material-ui/icons';
import { Form, Formik } from 'formik';
import { useToggle } from 'react-use';
import { UrlSearchParams } from '../../crm';
import UniqueTableHeadCells, { HeadCell } from './UniqueTableHeadCells';
import useUniqueTableHeadCells from '../../hooks/useUniqueTableHeadCells';
import { createFilterObject } from '../../helpers/createFilterObject';
import useDialog from '../../hooks/useDialog';
import { useSnackbar } from 'notistack';
import DownloadDialog from './DownloadDialog';
import DecliningCaseNumbersFilters from './DecliningCaseNumbers/DecliningCaseNumbersFilters';
import CrmToolbar from '../helpers/CrmToolbar';
import HelpDialog from '../helpers/HelpDialog';

const DecliningCaseNumbers = (): ReactElement => {
    const { enqueueSnackbar } = useSnackbar();
    const { user } = useContext<AuthContext>(AuthContext);
    const { order, orderBy, handleRequestSort, getStableSortMap } =
        useUniqueTableHeadCells<DecliningCases>('reference_period_cases');

    const [activeFilters, setActiveFilters] = useState<Obj | null>(null);
    const [decliningCases, setDecliningCases] = useState<Array<DecliningCases>>(
        []
    );
    const [refStartMonth, setRefStartMonth] = useState<string>();
    const [refEndMonth, setRefEndMonth] = useState<string>();
    const [lastMonth, setLastMonth] = useState<string>();
    const [page, setPage] = useState<number>(0);
    const [isLoading, setLoading] = useState(true);
    const [hasError, setError] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState<number>(25);

    const [showFilters, toggleShowFilters] = useToggle(false);

    const {
        dialogOpen: loadingDialogOpen,
        closeDialog: closeLoadingDialog,
        openDialog: openLoadingDialog,
    } = useDialog();

    const headCells: HeadCell<DecliningCases>[] = [
        { id: 'contact_name', label: 'Kontakt' },
        { id: 'current_period_cases', label: 'Aktuell' },
        { id: 'reference_period_cases', label: 'Ø Vergleichszeitraum' },
        { id: 'percent_difference', label: 'Differenz' },
    ];

    const helpers = [
        {
            heading: 'Fallzahlen:',
            text: `Die Anzahl an eingereichten Haftpflichtfällen eines Kontakts
            innerhalb eines bestimmten Zeitraums.`,
        },
        {
            heading: 'Aktuell:',
            text: 'Der letzte vollständige Monat.',
        },
        {
            heading: 'Vergleichszeitraum:',
            text: 'Die letzten 6 Monate vor dem aktuellen Zeitraum.',
        },
        {
            heading: 'Bedingungen (Aktuell > 0):',
            text: (
                <>
                    Wurde im aktuellen Monat min. ein Fall eingereicht, so
                    müssen diese Bedingungen erfüllt sein, damit der Kontakt
                    hier gelistet wird:
                    <ul style={{ margin: 0 }}>
                        <li>
                            Im Vergleichszeitraum wurden insgesamt min. 24 Fälle
                            eingereicht
                        </li>
                        <li>
                            Die Fallzahlen des aktuellen Monats liegen mehr als
                            15 % unter dem Durchschnitt des Vergleichszeitraums
                        </li>
                        <li>
                            Die Fallzahlen des aktuellen Monats sind niedriger
                            als die des Vormonats
                        </li>
                    </ul>
                </>
            ),
        },
        {
            heading: 'Bedingungen (Aktuell = 0):',
            text: (
                <>
                    Wurden im aktuellen Monat keine Fälle eingereicht, so müssen
                    diese Bedingungen erfüllt sein, damit der Kontakt hier
                    gelistet wird:
                    <ul style={{ margin: 0 }}>
                        <li>
                            Im Vergleichszeitraum wurden insgesamt min. 15 Fälle
                            eingereicht
                        </li>
                        <li>
                            Es wurde in einem der beiden Monate vor dem
                            aktuellen min. ein Fall eingereicht
                        </li>
                    </ul>
                </>
            ),
        },
    ];

    const onFiltersFormSubmit = (values: Obj) => {
        setActiveFilters(createFilterObject(values));
    };

    const fetchResults = async (searchParams?: UrlSearchParams) => {
        setLoading(true);

        if (!user) {
            return;
        }

        try {
            const response = await Ajax.get<DecliningCases[]>(
                'declining_cases/' + user.id + '/declining_case_numbers',
                searchParams
            );
            setDecliningCases(response);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(true);
            enqueueSnackbar(
                `Fehler beim Abrufen der rückgängigen Fallzahlen!`,
                {
                    variant: 'error',
                }
            );
            logError(error);
        }
    };

    const downloadXls = async () => {
        openLoadingDialog();

        if (!user) {
            return;
        }

        try {
            await Ajax.download(
                'declining_cases/' + user.id + '/declining_case_numbers/export',
                { ...activeFilters }
            );
        } catch (e) {
            enqueueSnackbar(`Fehler beim Herunterladen des Exportes!`, {
                variant: 'error',
            });
            logError(e);
        }

        closeLoadingDialog();
    };

    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        newPage: number
    ) => {
        setPage(newPage);
    };

    const sort = useMemo(
        () =>
            getStableSortMap(decliningCases, order, orderBy).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
            ),
        [decliningCases, order, page, rowsPerPage]
    );

    useEffect(() => {
        void fetchResults({ ...activeFilters });
        setLastMonth(getLastMonthNameAsString());
        setRefStartMonth(getMonthBeforeSevenMonthsNameAsString());
        setRefEndMonth(getPenultimateMonthNameAsString());
    }, [activeFilters]);

    return (
        <Paper>
            <CrmToolbar
                title="Kontakte mit rückgängigen Fallzahlen"
                subtitle={`im ${lastMonth} gegenüber dem Durchschnitt des Zeitraums ${refStartMonth} bis ${refEndMonth}`}
                buttons={
                    <>
                        <Tooltip
                            title="Liste als .xls herunterladen"
                            placement="top"
                            arrow
                        >
                            <IconButton
                                onClick={downloadXls}
                                disabled={hasError}
                            >
                                <GetApp />
                            </IconButton>
                        </Tooltip>
                        <IconButton
                            onClick={toggleShowFilters}
                            disabled={hasError}
                        >
                            <FilterList />
                        </IconButton>
                        <HelpDialog helpers={helpers} />
                    </>
                }
            />
            {showFilters && (
                <Formik initialValues={{}} onSubmit={onFiltersFormSubmit}>
                    <Form>
                        <Box m={2}>
                            <DecliningCaseNumbersFilters />
                        </Box>
                    </Form>
                </Formik>
            )}
            <TableContainer>
                <Table>
                    <UniqueTableHeadCells
                        headCells={headCells}
                        orderBy={orderBy}
                        order={order}
                        onRequestSort={handleRequestSort}
                    />
                    {!hasError ? (
                        <TableBody>
                            {!isLoading &&
                                (decliningCases.length !== 0 ? (
                                    sort.map(
                                        (decliningCase: DecliningCases) => (
                                            <DecliningCaseNumbersRows
                                                declinedCase={decliningCase}
                                                key={decliningCase.contact_id}
                                            />
                                        )
                                    )
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={4}>
                                            Momentan gibt es keine Kontakte mit
                                            rückgängigen Fallzahlen
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    ) : (
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <Typography color="error">
                                        Fallzahlen konnten nicht geladen werden
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    )}
                </Table>
                {isLoading && <LinearProgress />}
            </TableContainer>
            {!isLoading && (
                <TablePagination
                    rowsPerPageOptions={[10, 15, 25, 100]}
                    component="div"
                    count={decliningCases.length}
                    labelRowsPerPage="Einträge pro Seite:"
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            )}
            <DownloadDialog
                dialogOpen={loadingDialogOpen}
                closeDialog={closeLoadingDialog}
            />
        </Paper>
    );
};

export default DecliningCaseNumbers;
