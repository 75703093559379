import React, { ReactElement } from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

interface Props {
    children: ReactElement | ReactElement[] | string | string[];
}

const AlertBox = ({ children }: Props): ReactElement => {
    return (
        <Box mt={3}>
            <Paper>
                <Alert severity="warning">
                    <Typography>{children}</Typography>
                </Alert>
            </Paper>
        </Box>
    );
};

export default AlertBox;
