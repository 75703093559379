import React, { ReactElement } from 'react';
import { Button, IconButton, SvgIcon, Tooltip } from '@material-ui/core';
import { Delete, SvgIconComponent } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import Ajax from '../../../helpers/Ajax';
import logError from '../../../errors/logError';
import useConfirmationDialog from '../../../hooks/useConfirmationDialog';

interface Props {
    label: string;
    action: string;
    children: ReactElement;
    onDeleteHandler: { (): void };
    icon?: SvgIconComponent;
    size?: 'small' | 'medium';
}

const DeleteAction = ({
    label,
    action,
    children,
    onDeleteHandler,
    icon,
    size = 'medium',
}: Props): ReactElement => {
    const classes = useStyles();

    const onDelete = async (): Promise<void> => {
        try {
            await Ajax.delete(action);
            onDeleteHandler();
        } catch (e) {
            logError(e);
        }
    };

    const { confirmationDialog, openConfirmation } = useConfirmationDialog({
        children: children,
        confirmationButtonColor: 'error',
        confirmationButtonText: label,
        onConfirm: onDelete,
    });

    return (
        <>
            {icon ? (
                <Tooltip title={label}>
                    <IconButton
                        color="secondary"
                        size={size}
                        className={classes.error}
                        onClick={openConfirmation}
                    >
                        <SvgIcon component={icon} />
                    </IconButton>
                </Tooltip>
            ) : (
                <Button
                    color="secondary"
                    className={classes.error}
                    onClick={openConfirmation}
                >
                    {label}
                </Button>
            )}
            {confirmationDialog}
        </>
    );
};

export default DeleteAction;

const useStyles = makeStyles(theme => ({
    error: {
        color: theme.palette.error.main,
    },
}));
