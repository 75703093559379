import React, { useState } from 'react';

const useMenu = (): {
    isMenuOpen: boolean;
    handleMenuOpen(event: React.MouseEvent<HTMLElement>): void;
    handleMenuClose(): void;
} => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setIsMenuOpen(true);
    };

    const handleMenuClose = () => {
        setIsMenuOpen(false);
    };

    return { isMenuOpen, handleMenuOpen, handleMenuClose };
};

export default useMenu;
