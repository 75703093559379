const defaultPasswordLength = 12;

export const passwordChars =
    'ABCDEFGHJKLMNPRSTUVWXYZ' +
    'abcdefghijkmnpqrstuvwxyz' +
    '23456789' +
    '!$%&?';

const generate = (length: number = defaultPasswordLength): string =>
    [...Array(length)].reduce(previousValue => {
        const index = Math.floor(Math.random() * passwordChars.length);
        return previousValue + passwordChars.charAt(index);
    }, '');

export default generate;
