import React, { ReactElement } from 'react';
import {
    Box,
    IconButton,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Tooltip,
} from '@material-ui/core';
import {
    ExpandLess,
    ExpandMore,
    Info,
    LinkOff,
    PhoneDisabled,
} from '@material-ui/icons';
import Email from '../../helpers/formatting/Email';
import Phone from '../../helpers/formatting/Phone';
import { ContactPerson } from '../../../mappers/contactPersonMapper';
import { useToggle } from 'react-use';
import Short from '../ContactPerson/Short';

interface Props {
    contactPerson: ContactPerson;
    isEdited: boolean;
    handleContactPersonUnlink(contactPersonId: string): void;
}

const ContactPersonItem = ({
    contactPerson,
    isEdited,
    handleContactPersonUnlink,
}: Props): ReactElement => {
    const [showContactDetails, toggleContactDetails] = useToggle(false);
    return (
        <ListItem disableGutters key={contactPerson.id}>
            <ListItemText
                primary={<Short contactPerson={contactPerson} />}
                secondary={
                    <>
                        <Box component="span" display="block">
                            {contactPerson.position_name ?? ''}
                        </Box>
                        {showContactDetails && (
                            <Box mt={1}>
                                {contactPerson.phone && (
                                    <Box component="span" display="block">
                                        <Phone phone={contactPerson.phone} />
                                    </Box>
                                )}
                                {contactPerson.email && (
                                    <Box component="span" display="block">
                                        <Email email={contactPerson.email} />
                                    </Box>
                                )}
                            </Box>
                        )}
                    </>
                }
                secondaryTypographyProps={{ component: 'div' }}
            />

            <ListItemSecondaryAction>
                {isEdited ? (
                    <Tooltip
                        title={
                            (contactPerson.contacts?.length ?? 1) === 1
                                ? 'Der einzige zugewiesene Kontakt kann nicht entfernt werden.'
                                : ''
                        }
                        placement="top"
                        arrow
                    >
                        <span>
                            <IconButton
                                edge="end"
                                disabled={
                                    (contactPerson.contacts?.length ?? 1) === 1
                                }
                                onClick={() =>
                                    handleContactPersonUnlink(contactPerson.id)
                                }
                            >
                                <LinkOff fontSize="small" />
                            </IconButton>
                        </span>
                    </Tooltip>
                ) : (
                    <>
                        <>
                            {contactPerson.note && (
                                <Tooltip
                                    title="Zu diesem Ansprechpartner ist eine Notiz hinterlegt"
                                    placement="top"
                                    arrow
                                >
                                    <span>
                                        <IconButton size="small" disabled>
                                            <Info fontSize="inherit" />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            )}
                        </>
                        <>
                            {(contactPerson.phone || contactPerson.email) && (
                                <>
                                    {contactPerson.contacting_forbidden ? (
                                        <Tooltip
                                            title="Wünscht keine Kontaktaufnahme"
                                            placement="top"
                                            arrow
                                        >
                                            <span>
                                                <IconButton
                                                    size="small"
                                                    disabled
                                                >
                                                    <PhoneDisabled fontSize="inherit" />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                    ) : (
                                        <IconButton
                                            edge="end"
                                            onClick={toggleContactDetails}
                                        >
                                            {showContactDetails ? (
                                                <ExpandLess fontSize="small" />
                                            ) : (
                                                <ExpandMore fontSize="small" />
                                            )}
                                        </IconButton>
                                    )}
                                </>
                            )}
                        </>
                    </>
                )}
            </ListItemSecondaryAction>
        </ListItem>
    );
};

export default ContactPersonItem;
