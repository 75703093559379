import React, { ReactElement, useState } from 'react';
import {
    Box,
    IconButton,
    TableCell,
    TableRow,
    Typography,
} from '@material-ui/core';
import FormBuilder from '../../helpers/forms/FormBuilder';
import { ContactEntity } from '../../../helpers/entities';
import EntityAutocomplete from '../../helpers/forms/EntityAutocomplete';
import { Save } from '@material-ui/icons';
import { AutocompleteOption } from '../../../crm';
import { AboptimalUser } from '../../../mappers/aboptimalUserMapper';

interface Props {
    aboptimalUser: AboptimalUser;
    responseHandler: () => void;
}

const AboptimalUserRow = ({
    aboptimalUser,
    responseHandler,
}: Props): ReactElement => {
    const [contactId, setContactId] = useState<string | null>(
        aboptimalUser.contact?.id ?? null
    );

    const autocompleteChangeHandler = (
        newValue: AutocompleteOption | null
    ): void => setContactId(newValue?.value ?? null);

    return (
        <TableRow>
            <TableCell>
                <Typography variant="body1">{aboptimalUser.name}</Typography>
                <Typography variant="body2" color="textSecondary">
                    {aboptimalUser.id}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="body1">{aboptimalUser.address}</Typography>
                <Typography variant="body1">
                    {aboptimalUser.postcode} {aboptimalUser.city}
                </Typography>
            </TableCell>
            <TableCell>
                <FormBuilder
                    action={
                        ContactEntity.apiBaseUrl +
                        '/' +
                        (contactId ?? aboptimalUser.contact?.id)
                    }
                    initialValues={{}}
                    additionalValues={{
                        aboptimal_user_id: contactId ? aboptimalUser.id : null,
                    }}
                    responseHandler={responseHandler}
                    errorMessage="Fehler beim Zuordnen des Aboptimal-Benutzers."
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Box flexGrow={1}>
                            <EntityAutocomplete
                                entity={ContactEntity}
                                name="aboptimal_user_id"
                                selectedElement={aboptimalUser.contact}
                                onChangeHandler={autocompleteChangeHandler}
                                preloadAllEntries={false}
                            />
                        </Box>
                        <IconButton
                            type="submit"
                            disabled={
                                (contactId === null &&
                                    aboptimalUser.contact?.id === undefined) ||
                                contactId === aboptimalUser.contact?.id
                            }
                        >
                            <Save />
                        </IconButton>
                    </Box>
                </FormBuilder>
            </TableCell>
        </TableRow>
    );
};

export default AboptimalUserRow;
