import mapContact, { Contact } from './contactMapper';
import mapAboptimalUser, { AboptimalUser } from './aboptimalUserMapper';

export interface RentalCarReport {
    id: number;
    aboptimal_user_id: string;
    calculations: number;
    reported_at: Date;
    contact?: Contact | null;
    aboptimal_user?: AboptimalUser;
}

const mapRentalCarReport = (input: any): RentalCarReport => ({
    id: input.id,
    aboptimal_user_id: input.aboptimal_user_id,
    calculations: input.calculations,
    reported_at: new Date(input.reported_at),
    ...(input.contact && { contact: mapContact(input.contact) }),
    ...(input.aboptimal_user && {
        aboptimal_user: mapAboptimalUser(input.aboptimal_user),
    }),
});

export const mapRentalCarReports = (inputs: any[]): RentalCarReport[] =>
    inputs.map(mapRentalCarReport);

export default mapRentalCarReport;
