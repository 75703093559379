import React, { ReactElement } from 'react';
import { Button, Typography } from '@material-ui/core';
import FormBuilderCard from '../../helpers/forms/FormBuilderCard';
import { TextField } from 'formik-material-ui';
import { Field } from 'formik';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { useToggle } from 'react-use';

interface Props {
    note: string | null;
    apiUrl: string;
    reloadHandler(): void;
}

const Note = ({ note, apiUrl, reloadHandler }: Props): ReactElement => {
    const [isFieldExpanded, toggleIsFieldExtended] = useToggle(false);

    return (
        <FormBuilderCard
            title="Notizen"
            apiUrl={apiUrl}
            form={
                <>
                    <Field
                        component={TextField}
                        label="Notiz"
                        rows={isFieldExpanded ? 25 : 4}
                        name="note"
                        autoFocus
                        multiline
                    />
                    <Button
                        startIcon={
                            isFieldExpanded ? <ExpandLess /> : <ExpandMore />
                        }
                        onClick={toggleIsFieldExtended}
                        color="primary"
                    >
                        {isFieldExpanded ? 'weniger' : 'mehr'} anzeigen
                    </Button>
                </>
            }
            view={
                <>
                    {note ? (
                        <>
                            <Typography
                                variant="body2"
                                style={{ whiteSpace: 'pre-wrap' }}
                            >
                                {note}
                            </Typography>
                        </>
                    ) : (
                        'Bisher wurde keine Notiz angelegt.'
                    )}
                </>
            }
            reloadHandler={reloadHandler}
            initialValues={{
                note: note,
            }}
        />
    );
};

export default Note;
