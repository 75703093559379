import { Gender } from '../helpers/genderTranslation';
import { Contact } from './contactMapper';
import {
    ContactDetailsHierarchical,
    mapContactDetailsHierarchical,
} from './contactDetailMapper';
import mapAddress, { Address } from './addressMapper';
import { Position } from './positionMapper';

export interface ContactPerson {
    id: string;
    gender: Gender;
    title: ContactPersonTitle;
    first_name: string | null;
    last_name: string;
    name: string;
    nameSortable: string;
    position_id: number;
    position: Position;
    birthday: Date | null;
    contacts?: Contact[];
    main_contact: Contact | null;
    contact_details?: ContactDetailsHierarchical | null;
    address?: Address;
    note: string | null;
    email: string;
    phone: string;
    fax: string;
    web: string;
    is_archived: boolean;
    archived_at: Date | null;
    contacting_forbidden: boolean;
    position_name: string | null;
}

export type ContactPersonTitle = 'Dr.' | 'Prof. Dr.' | null;

const mapContactPerson = (input: any): ContactPerson => {
    const details = input.contact_details
        ? mapContactDetailsHierarchical(input.contact_details)
        : ({} as ContactDetailsHierarchical);

    return {
        ...input,
        ...(input.contact_details && {
            contact_details: details,
        }),
        ...(input.address && {
            address: mapAddress(input.address),
        }),
        main_contact: input.contacts?.length ? input.contacts[0] : null,
        web: details?.web?.length ? details.web[0].value : '',
        phone: details?.phone?.length ? details.phone[0].value : '',
        fax: details?.fax?.length ? details.fax[0].value : '',
        email: details?.email?.length ? details.email[0].value : '',
        name:
            input.name ??
            [input.title, input.first_name, input.last_name]
                .filter(Boolean)
                .join(' '),
        nameSortable:
            input.last_name +
            (input.first_name || input.title
                ? ', ' +
                  [input.title, input.first_name].filter(Boolean).join(' ')
                : ''),
        is_archived: !!input.archived_at,
        archived_at: input.archived_at ? new Date(input.archived_at) : null,
        contacting_forbidden: !!input.contacting_forbidden,
        birthday: input.birthday !== null ? new Date(input.birthday) : null,
    };
};

export const mapContactPersons = (input: any): ContactPerson[] =>
    input.map(mapContactPerson);

export default mapContactPerson;
