import React from 'react';
import { IconButton } from '@material-ui/core';
import { UserEntity } from '../helpers/entities';
import useDialog from '../hooks/useDialog';
import PaginationPage from './helpers/PaginationPage';
import { Add } from '@material-ui/icons';
import CrmDialog from './helpers/CrmDialog';
import UserRows from './modules/Users/UserRows';
import UserAddDialog from './modules/UserAddDialog';

const Users = () => {
    const { dialogOpen, closeDialog, openDialog } = useDialog();

    return (
        <PaginationPage
            entity={UserEntity}
            buttons={
                <IconButton onClick={openDialog}>
                    <Add />
                </IconButton>
            }
        >
            <UserRows />
            <CrmDialog dialogOpen={dialogOpen} closeDialog={closeDialog}>
                <UserAddDialog closeDialog={closeDialog} />
            </CrmDialog>
        </PaginationPage>
    );
};

export default Users;
