import Ajax from '../helpers/Ajax';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { UrlSearchParams } from '../crm';
import { Entity } from '../helpers/entities';
import logError from '../errors/logError';

const useEntities = <T>(
    entity: Entity<T>,
    urlSearchParams?: UrlSearchParams
): {
    entities: T[] | null;
    totalCount: number;
    reloadEntities(): Promise<void>;
} => {
    const [entities, setEntities] = useState<T[] | null>(null);
    const [totalCount, setTotalCount] = useState<number>(0);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setEntities(null);
        setTotalCount(0);
        reloadEntities();
    }, [JSON.stringify(urlSearchParams)]);

    const fetchEntities = async (
        searchParams?: UrlSearchParams
    ): Promise<{ entities: T[]; total: number }> => {
        try {
            const response: any = await Ajax.get(
                entity.apiBaseUrl,
                searchParams
            );

            // if a page is set, a paginated result is returned
            if (searchParams?.page !== undefined) {
                return {
                    entities: entity.arrayMapper(response.data),
                    total: response.total,
                };
            } else {
                return {
                    entities: entity.arrayMapper(response),
                    total: response.length,
                };
            }
        } catch (e) {
            enqueueSnackbar(`Fehler beim Laden der ${entity.namePlural}!`, {
                variant: 'error',
            });
            logError(e);
            return {
                entities: [],
                total: 0,
            };
        }
    };

    const reloadEntities = async () => {
        const { entities, total } = await fetchEntities(urlSearchParams);
        setEntities(entities);
        setTotalCount(total);
    };

    return { entities, totalCount, reloadEntities };
};

export default useEntities;
