import CallGenderizeIoValidationError from '../errors/callGenderizeIoErrors/CallGenderizeIoValidationError';
import CallGenderizeIoHttpValidationError from '../errors/callGenderizeIoErrors/CallGenderizeIoHttpValidationError';
import CallGenderizeIoHttpRequestLimitError from '../errors/callGenderizeIoErrors/CallGenderizeIoHttpRequestLimitError';
import CallGenderizeIoHttpError from '../errors/callGenderizeIoErrors/CallGenderizeIoHttpError';

interface GenderizeData {
    name: string;
    gender: string;
    probability: number;
    count: number;
}

enum Gender {
    Male = 'male',
    Female = 'female',
    Default = '',
}

export const callGenderizeIo = async (name: string): Promise<Gender> => {
    const options: RequestInit = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
    };

    if (name.length === 0) {
        throw new CallGenderizeIoValidationError('Parameter name is empty');
    }

    const urlSearchParams = new URLSearchParams({
        name: name,
    });

    const responseCallGenderizeIo = await fetch(
        `https://api.genderize.io?${urlSearchParams}`,
        options
    );

    if (!responseCallGenderizeIo.ok) {
        const body = await responseCallGenderizeIo.json();
        switch (responseCallGenderizeIo.status) {
            case 422:
                throw new CallGenderizeIoHttpValidationError(
                    responseCallGenderizeIo.statusText,
                    body.error
                );
            case 429:
                throw new CallGenderizeIoHttpRequestLimitError(
                    responseCallGenderizeIo.statusText,
                    body.error
                );
            default:
                throw new CallGenderizeIoHttpError(
                    responseCallGenderizeIo.statusText,
                    body.error
                );
        }
    }

    const data: GenderizeData = await responseCallGenderizeIo.json();

    if (data.gender !== Gender.Male && data.gender !== Gender.Female) {
        return Gender.Default;
    }

    return data.gender === Gender.Female ? Gender.Female : Gender.Male;
};
