import React, { ReactElement } from 'react';
import { UserEntity } from '../../../helpers/entities';
import EntityAutocomplete from '../../helpers/forms/EntityAutocomplete';
import { User } from '../../../mappers/userMapper';
import Ajax from '../../../helpers/Ajax';
import logError from '../../../errors/logError';
import { useSnackbar } from 'notistack';
import { AutocompleteOption } from '../../../crm';

interface Props {
    officeUnique: string;
    assignedCrmUser: User | null;
}

const OfficeUserAssignment = ({
    officeUnique,
    assignedCrmUser,
}: Props): ReactElement => {
    const { enqueueSnackbar } = useSnackbar();

    const changeAssignedCrmUser = async (
        newValue: AutocompleteOption | null
    ) => {
        try {
            await Ajax.post<never>(`offices/${officeUnique}/assign_crm_user`, {
                crm_user_id: newValue?.value ?? null,
            });
            enqueueSnackbar('Benutzer wurde erfolgreich zugewiesen', {
                variant: 'success',
            });
        } catch (error) {
            enqueueSnackbar('Benutzer konnte nicht zugewiesen werden', {
                variant: 'error',
            });
            logError(error);
        }
    };

    return (
        <EntityAutocomplete
            entity={UserEntity}
            name="assigned_crm_user_id"
            selectedElement={assignedCrmUser}
            onChangeHandler={changeAssignedCrmUser}
            withoutFormik
        />
    );
};

export default OfficeUserAssignment;
