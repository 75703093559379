export interface Campaign {
    id: number;
    campaign: string;
}

const mapCampaign: (input: any) => Campaign = input => ({
    ...input,
});

export const mapCampaigns: (input: any) => Campaign[] = input =>
    input.map(mapCampaign);

export default mapCampaign;
