import React from 'react';
import FormBuilder from '../../helpers/forms/FormBuilder';
import CrmToolbar from '../../helpers/CrmToolbar';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { Box, Grid, IconButton, MenuItem } from '@material-ui/core';
import { Block, Close, Edit, Save } from '@material-ui/icons';
import useEditable from '../../../hooks/useEditable';
import { User, userRoles } from '../../../mappers/userMapper';
import { UserEntity } from '../../../helpers/entities';
import DisabledChip from './DisabledChip';

interface Props {
    user: User;
    reloadUser(): void;
}

const Name = ({ user, reloadUser }: Props) => {
    const { isEdited, editOpen, editClose } = useEditable();

    const editResponseHandler = () => {
        editClose();
        reloadUser();
    };

    if (!user) {
        return null;
    }

    if (isEdited) {
        return (
            <FormBuilder
                action={UserEntity.apiBaseUrl + '/' + user.id}
                initialValues={{
                    first_name: user.first_name,
                    last_name: user.last_name,
                    role: user.role,
                }}
                responseHandler={editResponseHandler}
                errorMessage="Fehler beim Speichern des Namens!"
            >
                <CrmToolbar
                    title={
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <Field
                                    component={TextField}
                                    size="small"
                                    name="first_name"
                                    label="Vorname"
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Field
                                    component={TextField}
                                    size="small"
                                    name="last_name"
                                    label="Nachname"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Field
                                    component={TextField}
                                    name="role"
                                    label="Rolle"
                                    size="small"
                                    required
                                    select
                                >
                                    {Object.entries(userRoles).map(
                                        ([key, value]) => (
                                            <MenuItem key={key} value={key}>
                                                {value}
                                            </MenuItem>
                                        )
                                    )}
                                </Field>
                            </Grid>
                        </Grid>
                    }
                    disableTypography
                    buttons={
                        <>
                            <IconButton type="submit">
                                <Save />
                            </IconButton>
                            <IconButton edge="end" onClick={editClose}>
                                <Close />
                            </IconButton>
                        </>
                    }
                />
            </FormBuilder>
        );
    }

    return (
        <CrmToolbar
            title={
                <>
                    {user.name}
                    {user.isDisabled && (
                        <Box ml={2} component="span">
                            <DisabledChip />
                        </Box>
                    )}
                </>
            }
            subtitle={user.roleTranslation}
            buttons={
                <IconButton edge="end" onClick={editOpen}>
                    <Edit />
                </IconButton>
            }
        />
    );
};

export default Name;
