import React, { ReactElement } from 'react';
import FormattingBase from './FormattingBase';
import { Cake } from '@material-ui/icons';
import dayjs from 'dayjs';
import { getAge } from '../../../helpers/getAge';
import { daysUntilBirthdayText } from '../../../helpers/daysUntilBirthdayText';

interface Props {
    birthday: Date;
}

const Birthday = ({ birthday }: Props): ReactElement => {
    const birthdateDate = dayjs(birthday);
    const label = `${
        birthdateDate.format(
            'DD. MMMM' + (birthday.getFullYear() !== 1800 ? ' YYYY' : '')
        ) +
        (birthday.getFullYear() !== 1800
            ? ', ' + getAge(birthday, new Date()) + ' Jahre'
            : '') +
        daysUntilBirthdayText(birthdateDate)
    }`;
    return <FormattingBase label={label} icon={Cake} />;
};

export default Birthday;
