import { useEffect, useState } from 'react';
import Ajax from '../helpers/Ajax';
import { useSnackbar } from 'notistack';
import { Entity } from '../helpers/entities';
import HttpAuthorizationError from '../errors/HttpAuthorizationError';
import logError from '../errors/logError';
import useError from './useError';
import { isErrorInstance } from '../errors/error-helpers';

type RequestStatus = 'loading' | 'error' | 'error-forbidden' | 'ok';

const useEntity = <T>(
    entityDefinition: Entity<T>,
    entityId?: string | null
): { entity: T | null; reloadEntity(): void; requestStatus: RequestStatus } => {
    const [entity, setEntity] = useState<T | null>(null);
    const [requestStatus, setRequestStatus] =
        useState<RequestStatus>('loading');
    const { enqueueSnackbar } = useSnackbar();
    const { redirectIfNotFoundException } = useError();

    useEffect(() => {
        setEntity(null);
        entityId && getEntity();
    }, [entityId]);

    const getEntity = async (): Promise<void> => {
        setRequestStatus('loading');

        try {
            const response = await Ajax.get(
                entityDefinition.apiBaseUrl + '/' + entityId
            );

            setEntity(entityDefinition.mapper(response));
            setRequestStatus('ok');
        } catch (e) {
            if (redirectIfNotFoundException(e)) {
                return;
            }

            enqueueSnackbar(
                entityDefinition.name + ' konnte nicht geladen werden!',
                {
                    variant: 'error',
                }
            );
            setRequestStatus(
                isErrorInstance(e, HttpAuthorizationError)
                    ? 'error-forbidden'
                    : 'error'
            );
            logError(e);
        }
    };

    return { entity, reloadEntity: getEntity, requestStatus };
};

export default useEntity;
