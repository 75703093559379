import { Field } from 'formik';
import React, { ReactElement, useState } from 'react';
import { User } from '../../../mappers/userMapper';
import CrmCard from '../../helpers/CrmCard';
import FormBuilder from '../../helpers/forms/FormBuilder';
import { CheckboxWithLabel } from 'formik-material-ui';
import { Box, Button, Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import PasswordField from '../../helpers/forms/PasswordField';

interface Props {
    user: User;
}

const PasswordOverwrite = ({ user }: Props): ReactElement => {
    const [isReset, setIsReset] = useState(false);
    const onSuccess = () => {
        setIsReset(true);
    };

    return (
        <CrmCard title="Passwort überschreiben">
            {isReset ? (
                <Typography component="span">
                    <Check color="primary" fontSize="inherit" />
                    <Box component="span" ml={1}>
                        Passwort erfolgreich überschrieben.
                    </Box>
                </Typography>
            ) : (
                <FormBuilder
                    action={`users/${user.id}/overwrite-password`}
                    initialValues={{
                        password: '',
                        send_mail: false,
                    }}
                    responseHandler={onSuccess}
                >
                    <PasswordField
                        name="password"
                        label="Neues Passwort"
                        required
                    />
                    {!user.isDisabled && (
                        <Field
                            component={CheckboxWithLabel}
                            type="checkbox"
                            name="send_mail"
                            Label={{
                                label: 'Als E-Mail an den Nutzer schicken.',
                            }}
                        />
                    )}
                    <Box mt={1}>
                        <Button type="submit" color="primary">
                            Passwort überschreiben
                        </Button>
                    </Box>
                </FormBuilder>
            )}
        </CrmCard>
    );
};

export default PasswordOverwrite;
