export interface Group {
    id: number;
    group: string;
}

const mapGroup = (input: any): Group => ({
    ...input,
});

export const mapGroups = (input: any): Group[] => input.map(mapGroup);

export default mapGroup;
