import React, { ReactElement } from 'react';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    createStyles,
    Divider,
    Grid,
    IconButton,
    Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CrmTheme } from '../../theme';
import { LockOutlined } from '@material-ui/icons';

interface Props {
    title: string;
    titleAction?: any;
    children: any;
    footer?: any;
    disablePaddingTop?: boolean;
    adminBox?: boolean;
    marginTop?: boolean;
    titleColor?: 'textPrimary' | 'error';
    noContent?: boolean;
}

const CrmCard = ({
    title,
    titleAction,
    footer,
    children,
    disablePaddingTop,
    adminBox,
    marginTop,
    titleColor = 'textPrimary',
    noContent,
}: Props): ReactElement => {
    const classes = useStyles();

    return (
        <Card className={marginTop ? classes.card : undefined}>
            <CardHeader
                titleTypographyProps={{ variant: 'h3', color: titleColor }}
                className={classes.cardHeader}
                title={<Box mt={-1}>{title}</Box>}
                action={
                    <>
                        {titleAction}
                        {adminBox && (
                            <Tooltip
                                title="Diese Box ist nur für Administratoren sichtbar."
                                placement="top"
                                arrow
                            >
                                <span>
                                    <IconButton size="small" disabled>
                                        <LockOutlined />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        )}
                    </>
                }
            />
            {noContent ? (
                children
            ) : (
                <CardContent className={classes.cardContent}>
                    <Divider
                        className={disablePaddingTop ? '' : classes.divider}
                    />
                    {children}
                    {footer && (
                        <>
                            <Divider className={classes.divider} />
                            <Grid container justify="flex-end">
                                {footer}
                            </Grid>
                        </>
                    )}
                </CardContent>
            )}
        </Card>
    );
};

export default CrmCard;

const useStyles = makeStyles((theme: CrmTheme) =>
    createStyles({
        card: {
            marginTop: theme.spacing(2),
        },
        cardHeader: {
            paddingBottom: theme.spacing(1),
            minHeight: 46,
        },
        cardContent: {
            paddingTop: 0,
        },
        divider: {
            marginBottom: theme.spacing(2),
        },
    })
);
