import React, { ReactElement } from 'react';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    Typography,
} from '@material-ui/core';
import FormBuilder from '../../helpers/forms/FormBuilder';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import {
    ContactDetailType,
    ContactPersonContactDetails,
} from '../../../mappers/contactDetailMapper';
import ContactDetailItem from '../ContactDetails/ContactDetailItem';
import { Position } from '../../../mappers/positionMapper';
import { PositionEntity } from '../../../helpers/entities';
import EntityAutocomplete from '../../helpers/forms/EntityAutocomplete';
import { CovveContactPerson } from '../../../helpers/processCovveResponse';
import EditCloseAction from '../../helpers/actions/EditCloseAction';

interface Props {
    closeDialog: { (): void };
    contactId?: string;
    responseHandler(): void;
    scannedValues?: CovveContactPerson;
}

const ContactPersonAddDialog = ({
    closeDialog,
    contactId,
    responseHandler,
    scannedValues,
}: Props): ReactElement => {
    const initialValues = scannedValues
        ? scannedValues
        : ContactPersonContactDetails.reduce(
              (prev, [key]) => ({ ...prev, [key]: '' }),
              {
                  gender: '',
                  title: '',
                  first_name: '',
                  last_name: '',
                  position_id: '',
                  contact_id: contactId ?? '',
              }
          );

    return (
        <FormBuilder
            action="contact_persons"
            initialValues={initialValues}
            responseHandler={responseHandler}
            errorMessage="Fehler beim Speichern des Ansprechpartners!"
        >
            <DialogTitle>
                <Typography component="h2">
                    Ansprechpartner erstellen
                </Typography>
                <EditCloseAction editClose={closeDialog} edge="end" />
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={6}>
                        <Field
                            component={TextField}
                            name="gender"
                            label="Anrede"
                            autoFocus
                            required
                            select
                            fullWidth
                        >
                            <MenuItem value="m">Herr</MenuItem>
                            <MenuItem value="f">Frau</MenuItem>
                            <MenuItem value="d">Divers</MenuItem>
                        </Field>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Field
                            component={TextField}
                            name="title"
                            label="Titel"
                            select
                            fullWidth
                        >
                            <MenuItem value="">&nbsp;</MenuItem>
                            <MenuItem value="Dr.">Dr.</MenuItem>
                            <MenuItem value="Prof. Dr.">Prof. Dr.</MenuItem>
                        </Field>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Field
                            component={TextField}
                            name="first_name"
                            label="Vorname"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Field
                            component={TextField}
                            name="last_name"
                            label="Nachname"
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <EntityAutocomplete<Position>
                            entity={PositionEntity}
                            name="position_id"
                            minInputLength={0}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h3">Kontaktdaten</Typography>
                    </Grid>
                    {ContactPersonContactDetails.map(key => (
                        <Grid item xs={6} key={key}>
                            <ContactDetailItem
                                type={key as ContactDetailType}
                                isEdited
                            />
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button type="submit" color="primary">
                    Ansprechpartner erstellen
                </Button>
            </DialogActions>
        </FormBuilder>
    );
};

export default ContactPersonAddDialog;
