import CallGenderizeIoHttpError from './CallGenderizeIoHttpError';

export default class CallGenderizeIoHttpRequestLimitError extends CallGenderizeIoHttpError {
    constructor(message: string, error: string) {
        super(message + ': ', error);
        if (Error?.captureStackTrace) {
            Error.captureStackTrace(this, CallGenderizeIoHttpRequestLimitError);
        }
        this.name = 'CallGenderizeIoHttpRequestLimitError';
        Object.setPrototypeOf(
            this,
            CallGenderizeIoHttpRequestLimitError.prototype
        );
    }
}
