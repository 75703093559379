import { callGenderizeIo } from './callGenderizeIo';
import logError from '../errors/logError';

export type CovveApiResponse = {
    id: string;
    firstName: string;
    middleName: string;
    lastName: string;
    emails: { address: string }[];
    addresses: { fullAddress: string }[];
    jobs: { company: string; title: string }[];
    phones: { number: string }[];
    websites: { url: string }[];
    notes: string;
    language: string;
};

export type CovveContact = {
    name: string;
    address: string;
    postcode: string;
    city: string;
    longitude: null;
    latitude: null;
    group_id: null;
    phone: string;
    email: string;
    web: string;
};

export type CovveContactPerson = {
    gender: string;
    title: string;
    first_name: string;
    last_name: string;
    position_id: string;
    contact_id: string;
    email: string;
    web: string;
    phone: string;
    fax: string;
    mobile: string;
};

function getTitle(name: string): string {
    switch (true) {
        case /(Dr\.)/i.test(name):
            return 'Dr.';
        case /(Prof\.) Dr\./i.test(name):
            return 'Prof. Dr.';
        default:
            return '';
    }
}

async function getGender(name: string): Promise<string> {
    try {
        if (name.length === 0) return '';

        const gender = await callGenderizeIo(name);

        switch (true) {
            case gender === 'male':
                return 'm';
            case gender === 'female':
                return 'f';
            default:
                return '';
        }
    } catch (error) {
        logError(error);
        return '';
    }
}

function getAddress(addresses: { fullAddress: string }[]): Obj | null {
    const regexp = /^(.+?)\s*(\d{5})\s*(.+)$/i;
    return addresses.length > 0 ? regexp.exec(addresses[0].fullAddress) : null;
}

function getMobile(phones: { number: string }[]): string {
    const regex =
        /(^\+491[5-7])|(^01[5-7])|(^(\+49[ ]1[5-7]))|(\+\(49\)?[ ]1[5-7])|(^(\(\+49\))?[ ]1[5-7])/i;

    if (phones.length === 0) return '';

    switch (true) {
        case regex.test(phones[0].number):
            return phones[0].number;
        case phones.length < 2:
            return '';
        case regex.test(phones[1].number):
            return phones[1].number;
        case phones.length < 3:
            return '';
        case regex.test(phones[2].number):
            return phones[2].number;
        default:
            return '';
    }
}

function getPhone(phones: { number: string }[], mobile: string): string {
    if (phones.length === 0) return '';

    switch (true) {
        case phones[0].number === mobile && phones.length > 2:
            return phones[1].number;
        case phones.length < 2:
            return '';
        default:
            return phones[0].number;
    }
}

function getFax(
    phones: { number: string }[],
    mobile: string,
    phone: string
): string {
    if (phones.length === 0) return '';

    switch (true) {
        case phones.length < 2:
            return '';
        case phones[1].number !== mobile && phones[1].number !== phone:
            return phones[1].number;
        case phones.length < 3:
            return '';
        case phones[2].number !== mobile && phones[2].number !== phone:
            return phones[2].number;
        default:
            return phones[0].number;
    }
}

function removeSpace(remove: string | null): string | null {
    return remove ? remove.replace(/\s/g, '') : '';
}

export const processCovveResponse = async (
    response: CovveApiResponse,
    contactId?: string
): Promise<CovveContactPerson | CovveContact> => {
    const { addresses, jobs, phones, emails, websites } = response;
    const isContactPerson = !!contactId;

    const title = isContactPerson ? getTitle(response.firstName) : '';

    const gender = isContactPerson
        ? await getGender(!title ? response.firstName : response.middleName)
        : '';

    const mobile = isContactPerson ? getMobile(phones) : '';

    const phone = mobile !== '' ? getPhone(phones, mobile) : phones[0]?.number;

    const fax =
        mobile !== '' ? getFax(phones, mobile, phone) : phones[1]?.number;

    const matchedFullAddress = !isContactPerson ? getAddress(addresses) : {};

    return !isContactPerson
        ? ({
              name: jobs[0]?.company ?? '',
              address: matchedFullAddress?.[1] ?? '',
              postcode: matchedFullAddress?.[2] ?? '',
              city: matchedFullAddress?.[3] ?? '',
              longitude: null,
              latitude: null,
              group_id: null,
              phone: removeSpace(phone),
              email: emails[0]?.address ?? '',
              web: websites[0]?.url ?? '',
          } as CovveContact)
        : ({
              gender: gender,
              title: title,
              first_name:
                  title.length === 0
                      ? response?.firstName ?? ''
                      : response.middleName ?? '',
              last_name: response?.lastName ?? '',
              position_id: '',
              contact_id: contactId ?? '',
              email: emails[0]?.address ?? '',
              web: websites[0]?.url ?? '',
              phone: removeSpace(phone),
              fax: removeSpace(fax),
              mobile: removeSpace(mobile),
          } as CovveContactPerson);
};
