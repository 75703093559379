import React, { ReactElement } from 'react';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import 'typeface-roboto';
import theme from '../theme';
import AuthStore from '../stores/AuthStore';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import GlobalStore from '../stores/GlobalStore';
import Main from './Main';
import { SnackbarProvider } from 'notistack';
import ConfigStore from '../stores/ConfigStore';
import { initializeSentry } from '../../../../../../resources/assets/react/funcs/error-handling';
import ReportDoneNotification from './modules/ReportDoneNotification';
import Unauthenticated from './Unauthenticated';

initializeSentry(window.Voigt.sentryDsn, window.Voigt.environment, 'crm');

const App = (): ReactElement => {
    return (
        <BrowserRouter
            basename={window.Voigt.environment === 'local' ? '/crm' : '/'}
        >
            <ThemeProvider theme={theme}>
                <Switch>
                    <Route
                        exact
                        path="/report_done"
                        component={ReportDoneNotification}
                    />
                    <Route exact path="/401" component={Unauthenticated} />
                    <Route>
                        <SnackbarProvider maxSnack={3}>
                            <ConfigStore>
                                <AuthStore>
                                    <GlobalStore>
                                        <CssBaseline />
                                        <Main />
                                    </GlobalStore>
                                </AuthStore>
                            </ConfigStore>
                        </SnackbarProvider>
                    </Route>
                </Switch>
            </ThemeProvider>
        </BrowserRouter>
    );
};

export default App;
