import React, { ReactElement, useState } from 'react';
import {
    Box,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
} from '@material-ui/core';
import { Report, reportTypes } from '../../mappers/reportMapper';
import ReportDialog from './ReportDialog';
import useDialog from '../../hooks/useDialog';
import { CallMade } from '@material-ui/icons';
import WarningIcon from '../helpers/WarningIcon';

interface Props {
    reports: Report[];
    startEntryIndex?: number;
    endEntryIndex?: number;
    reportChangeHandler?(): void;
    hideContactName?: boolean;
}

const ReportList = ({
    reports,
    startEntryIndex,
    endEntryIndex,
    reportChangeHandler,
    hideContactName,
}: Props): ReactElement => {
    const { dialogOpen, closeDialog, openDialog } = useDialog();
    const [dialogReportId, setDialogReportId] = useState<string | null>(null);

    const handleDialogOpen = (reportId: string) => {
        setDialogReportId(reportId);
        openDialog();
    };

    const slicedReports =
        startEntryIndex !== undefined
            ? reports.slice(startEntryIndex, endEntryIndex)
            : reports;

    return (
        <List dense disablePadding>
            {slicedReports.map(report => (
                <ListItem key={report.id} disableGutters>
                    <ListItemText
                        primary={
                            <>
                                <Typography
                                    component="div"
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    <Box component="span">
                                        {reportTypes[report.type]} am{' '}
                                        {report.date_formatted}
                                        {report.notification_date &&
                                            !report.done && (
                                                <>
                                                    {' ('}
                                                    {report.overdue && (
                                                        <WarningIcon />
                                                    )}
                                                    {`Wiedervorlage am ${report.notification_date_formatted})`}
                                                </>
                                            )}
                                    </Box>

                                    <Box component="span" mx={1}>
                                        -
                                    </Box>
                                    <Box component="span">{report.subject}</Box>
                                </Typography>
                                <Typography variant="body2">
                                    {!hideContactName &&
                                        `${report.contact?.name} / `}
                                    {report.user?.name}
                                    {report.contact_persons.length > 0 ? (
                                        <>
                                            <Box mx={1} component="span">
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    component="span"
                                                >
                                                    mit
                                                </Typography>
                                            </Box>
                                            {report.contact_persons
                                                .map(
                                                    contactPerson =>
                                                        contactPerson.name
                                                )
                                                .join(', ')}
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </Typography>
                            </>
                        }
                        secondary={report.report_excerpt}
                    />
                    <ListItemSecondaryAction>
                        <IconButton
                            edge="end"
                            onClick={() => handleDialogOpen(report.id)}
                        >
                            <CallMade />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            ))}

            <ReportDialog
                dialogOpen={dialogOpen}
                reportId={dialogReportId}
                closeDialog={closeDialog}
                responseHandler={reportChangeHandler}
            />
        </List>
    );
};

export default ReportList;
