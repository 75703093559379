export enum Order {
    ASC = 'asc',
    DESC = 'desc',
}

export const getStableSortMap = <T>(
    array: T[],
    order: Order,
    orderBy: keyof T
): T[] => {
    return stableSortMap(array, getComparator(order, orderBy));
};

/* Source: https://mui.com/components/tables/#EnhancedTable.tsx*/
function stableSortMap<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

/* Source: https://mui.com/components/tables/#EnhancedTable.tsx*/
function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

/* Source: https://mui.com/components/tables/#EnhancedTable.tsx*/
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
