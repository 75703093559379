import React, { ReactElement } from 'react';

export interface ConfigContext {
    segmentationOptions: string[];
}
export const ConfigContext = React.createContext<ConfigContext>(
    {} as ConfigContext
);

interface Props {
    children: any;
}

const ConfigStore = ({ children }: Props): ReactElement => {
    const segmentationOptions = ['', 'Hoch', 'Mittel', 'Niedrig'];

    return (
        <ConfigContext.Provider value={{ segmentationOptions }}>
            {children}
        </ConfigContext.Provider>
    );
};

export default ConfigStore;
